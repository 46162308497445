import { Link, useNavigate } from "react-router-dom";
import { useGetActiveOperatorsQuery } from "../../Features/clientsSlice";
import Home from "../../HeaderLayouts/Home";
import { useGetAllImagesForFrontEndQuery } from "../../Features/frontEndImagesSlice";
import FrontEndLoadingSpinner from "../FrontEndLoadingSpinner";
import { useGetAllFeaturedProductsQuery, useGetBestSellingProductsQuery } from "../../Features/productsSlice";
import { useGetAllTopCategoriesQuery } from "../../Features/topCategoriesSlice";
import { useGetAllBrandsQuery } from "../../Features/brandsSlice";
import CarouselAutoSlider from "./CarouselAutoSlider";
import { useEffect, useState } from "react";
import CarouselCommentsSliderView from "./CarouselCommentsSliderView";
import ProductsCarouselSliderView from "./ProductsCarouselSliderView";

function HomeView(props) {  
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    
    const {
        data: top_categories,
        isLoading: isLoadingTopCategories,
        isSuccess: isSuccessTopCategories,
        isError: isErrorTopCategories,
        error: errorTopCategories
      } = useGetAllTopCategoriesQuery()

    const {
      data: featuredProducts,
      isLoading: isLoadingFeaturedProducts,
      isSuccess: isSuccessFeaturedProducts,
      isError: isErrorFeaturedProducts,
      error: errorFeaturedProducts
    } = useGetAllFeaturedProductsQuery()

    const {
        data: brands,
        isLoading: isLoadingBrands,
        isSuccess: isSuccessBrands,
        isError: isErrorBrands,
        error: errorBrands
      } = useGetAllBrandsQuery()

    const {
        data: bestSellingProducts,
        isLoading: isLoadingBestSellingProducts,
        isSuccess: isSuccessBestSellingProducts,
        isError: isErrorBestSellingProducts,
        error: errorBestSellingProducts
      } = useGetBestSellingProductsQuery()

    const {
        data: images,
        isLoading: isLoadingImages,
        isSuccess: isSuccessImages,
        isError: isErrorImages,
        error: errorImage
      } = useGetAllImagesForFrontEndQuery()

      useEffect(()=>{
        window.scrollTo(0, 0)
        return () =>{
        }
      },[]);

      const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };

    return (
        <div className='bg-gray-50'>
            <Home />
            <div className="flex flex-wrap">
                <div className="md:w-2/3 w-full md:pl-5 pt-5 mb-3 md:mb-0">
                    <CarouselAutoSlider />
                </div>
                <div className="md:w-1/3 w-full md:pl-5 pt-5 md:pr-5">
                <section>
                    <div className='relative bg-white'>
                        <ul className='overflow-hidden transition-margin duration-1000 ease-in-out'>
                        <li
                            className={`max-h-96 relative ${isHovered ? 'transform scale-125 transition-all duration-500' : ''}`}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img
                            src={`https://demoapi.autozedltd.com/uploads/result.png`}
                            className='h-96 w-full object-cover md:rounded-lg'
                            alt='Image'
                            />
                            {/* <div className='absolute top-0 left-0 h-96 w-full flex justify-center items-center text-black'>
                                <div className="flex flex-col items-start">
                                    <h2 className='flex items-center text-lg font-bold w-full text-center tracking-tighter'>Accessories</h2>
                                    <h2 className='flex items-center text-4xl font-extrabold w-full text-center leading-4 tracking-tighter'>ALL BRAND</h2>
                                    <h2 className='flex items-center text-4xl font-extrabold w-full text-center tracking-tighter'>AND SIZE</h2>
                                    <h2 className='flex items-center text-md font-semibold w-full text-center mt-2'>Now available for buy</h2>
                                    <h2 className='flex items-center text-md font-semibold w-full text-center'>online</h2>
                                </div>
                            </div> */}
                        </li>
                        </ul>
                    </div>
                    </section>
                </div>
            </div>
            <div className="">
                {
                    (isSuccessTopCategories)?
                        <section className="text-gray-600 body-font">
                            <div className="container md:px-2 py-24 mx-auto">
                            <div className="flex flex-col">
                                <h1 className="pb-4 font-extrabold text-lg text-gray-900 uppercase">Top Categories</h1>
                                <div className="flex flex-wrap items-center  rounded-md">
                                {
                                    (top_categories.data.length > 0)?
                                    top_categories.data.map(data => (
                                        <div className="flex md:w-1/3 bg-gray-50 space-x-2 md:space-x-10 pb-1 pt-1 pl-6 bpr-6 rounded-sm border-b border-gray-200">
                                            <img 
                                                className="object-contain h-40 w-40 rounded-t-md shodow-lg group-hover:opacity-50"
                                                src={`https://demoapi.autozedltd.com/uploads/`+data.image} 
                                                alt="Image" 
                                            />
                                            <div className="pl-6">
                                                <Link to={`/shop/${data.name}`} className="text-gray-900 font-bold text-md cursor-pointer title-font mb-2">{data.name}</Link>
                                                <div className="leading-relaxed text-base">
                                                    <p className='text-gray-600 text-sm hover:text-yellow-600 pb-1'>{data.item_1}</p>
                                                    <p className='text-gray-600 text-sm hover:text-yellow-600 pb-1'>{data.item_2}</p>
                                                    <p className='text-gray-600 text-sm hover:text-yellow-600 pb-1'>{data.item_3}</p>
                                                    <p className='text-gray-600 text-sm hover:text-yellow-600 pb-1'>{data.item_4}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :null
                                }
                                </div>
                            </div>
                            </div>
                        </section>
                    :null
                }
                {
                    (isSuccessFeaturedProducts)?
                        <div className="flex flex-col bg-gray-50">
                            <h1 className="ml-5 font-extrabold text-lg text-gray-900 uppercase">Featured Products</h1>
                            <ProductsCarouselSliderView props={featuredProducts} />
                        </div>
                    :null
                }

                <div className="flex flex-col bg-gray-50 mb-10 mt-20">
                    <h1 className="ml-5 font-extrabold text-lg text-gray-900 uppercase">Specials & Promotions</h1>
                    <div className="flex flex-wrap">
                        <div className="md:w-2/5 w-full md:pl-5 pt-5 mb-3 md:mb-0">
                            <section>
                                <div className='relative'>
                                    <ul className='overflow-hidden transition-margin duration-1000 ease-in-out'>
                                    <li
                                        className={`max-h-96 relative transform`}
                                    >
                                        <img
                                        src={`https://demoapi.autozedltd.com/uploads/image1.png`}
                                        className='h-96 w-full object-cover'
                                        alt='Image'
                                        />
                                        <div className='absolute top-0 left-0 h-96 w-full flex'>
                                        <h2 className='text-lg font-bold text-white my-auto w-full text-center p-20'></h2>
                                        </div>
                                    </li>
                                    </ul>
                                    <div className='absolute px-5 flex h-full w-full top-0 left-0'>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="md:w-3/5 w-full flex flex-col md:pl-5 pt-5 md:pr-5">
                            <section className="p-1">
                                <div className='relative bg-yellow-300'>
                                    <ul className='overflow-hidden transition-margin duration-1000 ease-in-out'>
                                    <li
                                        className={`max-h-48 relative ${isHovered ? 'transform-left scale-125 transition-all duration-500' : ''}`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <img
                                        src={`https://demoapi.autozedltd.com/uploads/image2.jpg`}
                                        className='h-96 w-full object-fill'
                                        alt='Image'
                                        />
                                        <div className='absolute -top-20 left-10 h-96 w-full flex justify-start items-center text-black'>
                                            <div className="flex flex-col items-start">
                                                <h2 className='flex items-center text-lg font-bold w-full text-center text-rose-600'>SAVE UP TO K150</h2>
                                                <h2 className='flex items-center text-2xl font-bold w-full text-center text-white'>BASIC AUTOMOTIVE</h2>
                                                <h2 className='flex items-center text-4xl font-bold w-full text-center text-white'>ENGINE PARTS</h2>
                                                <Link to={`/shop/all`} className='flex items-center text-md uppercase text-white font-bold w-full text-center mt-3 underline'>Shop Now</Link>
                                            </div>
                                        </div>
                                    </li>
                                    </ul>
                                </div>
                            </section>
                            <section className="p-1 flex w-full">
                                <div className='relative w-full'>
                                    <ul className='overflow-hidden transition-margin duration-1000 ease-in-out'>
                                    <li
                                        className={`max-h-48 relative ${isHovered ? 'transform-left scale-125 transition-all duration-500' : ''}`}
                                    >
                                        <img
                                        src={`https://demoapi.autozedltd.com/uploads/image3.png`}
                                        className='h-48 w-full object-fill opacity-0'
                                        alt='Image'
                                        />
                                        <div className='absolute -top-20 left-10 h-96 w-full flex justify-start items-center text-black'>
                                            <div className="flex flex-col items-start">
                                                <h2 className='flex items-center text-3xl font-bold w-full text-center text-white'>LED CAR</h2>
                                                <h2 className='flex items-center text-3xl font-bold w-full text-center text-rose-600'>LIGHTING</h2>
                                                <h2 className='flex items-center text-3xl font-bold w-full text-center text-white'>INNOVATION</h2>
                                                <h2 className='flex items-center text-lg uppercase text-yellow-400 font-bold w-full text-center'>Up to two years warranty</h2>
                                            </div>
                                        </div>
                                    </li>
                                    </ul>
                                </div>
                                <div className='relative w-full'>
                                    <ul className='overflow-hidden transition-margin duration-1000 ease-in-out'>
                                    <li
                                        className={`max-h-48 relative ${isHovered ? 'transform-left scale-125 transition-all duration-500' : ''}`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <img
                                        src={`https://demoapi.autozedltd.com/uploads/image3.png`}
                                        className='h-48 w-full object-fill'
                                        alt='Image'
                                        />
                                        {/* <div className='absolute -top-28 left-10 h-96 w-full flex justify-start items-center text-black'>
                                            <div className="flex flex-col items-start">
                                                <h2 className='flex items-center text-4xl font-bold w-full text-center text-white text-yellow-400'>GENUINE</h2>
                                                <h2 className='flex items-center text-4xl font-bold w-full text-center text-white text-yellow-400'>PARTS</h2>
                                                <Link to={`/shop/all`} className='flex items-center text-md uppercase text-white font-bold w-full text-center mt-3 underline'>Shop Now</Link>
                                            </div>
                                        </div> */}
                                    </li>
                                    </ul>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                {
                    (isSuccessBrands)?
                        <div className="flex flex-col mb-8 mt-20">
                            <h1 className="ml-5 mr-5 font-extrabold text-lg text-gray-900 uppercase border-b border-gray-200 p-2">Parts By Brand</h1>
                            <div className='mr-5 ml-5 flex flex-wrap justify-between items-center pt-5 pb-5'>
                                {
                                    (brands.data.length > 0)?
                                        brands.data.map(data => (
                                            <Link to={`/shop/${data.name}`} className="space-x-10 mb-3 cursor-pointer">
                                                <img 
                                                    className="object-fill h-30 w-40 shodow-lg hover:opacity-50"
                                                    src={`https://demoapi.autozedltd.com/uploads/`+data.brand_logo} 
                                                    alt="Image" 
                                                />
                                            </Link>
                                        ))
                                    :null
                                }
                            </div>
                        </div>
                    :null
                }

                {
                    (isSuccessBestSellingProducts)?
                        <div className="flex flex-col bg-gray-50">
                            <h1 className="ml-5 font-extrabold text-lg text-gray-900 uppercase">Best Seller</h1>
                            <ProductsCarouselSliderView props={bestSellingProducts} />
                        </div>
                    :null
                }

                <div className="flex mb-8 mt-20">
                    <div className="w-full">
                        <CarouselCommentsSliderView />
                    </div>
            </div>
            </div>
        </div>
    )
}

export default HomeView;
