import { ChevronLeftIcon, ChevronRightIcon, SearchIcon, UserCircleIcon } from '@heroicons/react/outline';
import LoadingSpinner from '../LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '../../Features/usersSlice';
import Pagination from 'rc-pagination';
import { useState } from 'react';

function AllUsersView({props}) {
    const navigate = useNavigate();
    const defaultPagination = 7;
    const [search, setSearch] = useState(0)
    const [pager, setPager] = useState("")

    const {
      data: users,
      isLoading,
      isSuccess,
      isError,
      error
    } = useGetUsersQuery({paginate: defaultPagination, search: search, page: pager})

    const [input, setInput] = useState({
      search: ''
    })

  const handleChange = (e) => {
    setInput({...input, [e.target.name]: e.target.value})
    if(input.search != 0){
      setSearch(input.search)
    }else {

      setSearch(0)
    }
  }

    const UserProfile = (e,id) =>{
      navigate("/user/manage-user-profile/"+id);
    }

    const onChange = (currentPage) => {
      setPager(currentPage)
    }

    return (
      <div className="bg-gray-100">
         <div className="md:flex items-center md:justify-between bg-white p-1">
          <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">System users</h1>
          <form className="w-full max-w-md">
              <div className="relative flex items-center text-gray-400 focus-within:text-gray-600">
                <SearchIcon className="w-4 h-4 absolute ml-3 pointer-events-none" />
                <input 
                  type="text"
                  name="search"
                  value={input.search}
                  onChange={handleChange}
                  placeholder="Search user"
                  autoComplete="off"
                  aria-label="Search user"
                  className="w-full pr-3 pl-10 px-3 py-1 bg-gray-300 text-sm placeholder-gray-500 text-black rounded-2xl border-none outline-none"
                /> 
              </div>
            </form>
          </div>
        {(isSuccess)?
              (users.hasOwnProperty('success') === true && users.data.data.length > 0)?
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Details
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Contact
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-50 divide-y divide-gray-200">
                    { (isSuccess)? users.data.data.map(data => (
                      <tr key={data.id} className="hover:bg-gray-100">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10">
                              <UserCircleIcon className="h-8 text-gray-300 w-10 rounded-full"  />
                            </div>
                            <div className="ml-4">
                                <div className="flex items-center space-x-2">
                                    <div className="text-sm font-medium text-gray-900">{data.lname}</div>
                                    <div className="text-sm font-medium text-gray-900">{data.fname}</div>
                                </div>
                              <div className="text-sm text-gray-500">{data.user_id}</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{data.email}</div>
                          <div className="text-sm text-gray-500">{data.phone}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm ">
                          <button onClick={(e)=>UserProfile(e,data.id)} className="hover:bg-gray-300 bg-gray-200 p-1 rounded-md w-20 text-indigo-800 cursor-pointer font-semibold hover:text-indigo-900">
                            Profile
                          </button>
                        </td>
                      </tr>
                    ))
                    :
                    ""
                }
                  </tbody>
                </table>
                :
                  <div>No users record found</div>
            : isLoading?
                  <LoadingSpinner />
              : isError?
                  <div className="md:flex p-4">
                    <p className="text-blue-500 italic border-b border-black flex flex-1">Data not found</p>
                  </div>
              : null
            }
            <div className="flex justify-center p-4 mt-4">
            <div>
            {(isSuccess)?
              (users.hasOwnProperty('success') === true && users.data.data.length > 0)?
              
              <Pagination
                className="flex items-center space-x-3 text-blue-800 cursor-pointer"
                // activePage={this.state.activePage}

                defaultPageSize={7}
                total={users.data.total}
                current={users.data.current_page}
                onChange={onChange}
                prevIcon={<ChevronLeftIcon className='h-7 text-white bg-blue-500 cursor-pointer hover:text-gray-300' />}
                nextIcon={<ChevronRightIcon className='h-7 text-white bg-blue-500 cursor-pointer hover:text-gray-300' />}
              />
              : null
              : null
            }
            </div>
            </div>
      </div>
)
}

export default AllUsersView;
