import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from 'react';
import {ChevronRightIcon, ChevronLeftIcon, ShoppingCartIcon } from '@heroicons/react/solid';
import { useState } from "react";
import { useRecoilState } from "recoil";
import { CartState } from "../../CartState";
import toast from 'react-hot-toast';
import { EyeIcon, XIcon } from "@heroicons/react/outline";
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import swal from "sweetalert";
import { useGetProductByIdMutationMutation } from "../../Features/productsSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Pagination,Navigation
  } from 'swiper/core';
  
  // install Swiper modules
  SwiperCore.use([Autoplay,Pagination,Navigation]);

function ProductsCarouselSliderView(props) {  
    const navigate = useNavigate();

    const sliderContainerRef = useRef(null);
    const sliderRef = useRef(null);
    const [elementsToShow, setElementsToShow] = useState(1);    
    const [isPaused, setIsPaused] = useState(false);
    const [open, setOpen] = useState(false)
    const [product, setProduct] = useState(null);
    const [activeImage, setActiveImage] = useState(false)
    const cancelButtonRef = useRef(null)
    const [isHovered, setIsHovered] = useState(false);
    const [cardId, setCardId] = useState(false);

    const [cartItem, setCartItem] = useRecoilState(CartState)

    const addItemToCart = (e, product)=>{
      if(cartItem.findIndex(pro => pro.product_id === product.product_id) === -1){
        setCartItem(prevState => [...prevState, {...product, total_quantity: 1}])
      }else{
        setCartItem(prevState => {
          return prevState.map((item)=>{
            return item.product_id === product.product_id ? {...item, total_quantity: (item.total_quantity)? item.total_quantity + 1 : 1} : item
          })
        })
      }

      toast(`${product.name} Added to cart `);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
          };
    }, ['']);

      const handleItemHover = (id) => {
          setIsPaused(true);
          setCardId(id)
          setIsHovered(true);
        };
      
        const handleItemLeave = (id) => {
          setIsPaused(false);
          setCardId(id)
          setIsHovered(false);
        };
  
      const trancateText = (str)=> {
          return str.length > 40 ? str.substring(0, 40) + "..." : str;
      }
  
      const numberWithCommas = (x)=>{
        if(x !==null){
          const formatedNumber = x.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  });
          return formatedNumber;
        }
        return null;
    }
  
    const [getProductByIdMutation] = useGetProductByIdMutationMutation()
  
    const handleViewProduct = async(id)=>{
      try {
          const resp = await getProductByIdMutation(id).unwrap()
          setIsPaused(true);
          setProduct(resp)
          setActiveImage(resp.data['0'].product.image);
          setOpen(true)
        } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
            swal("Error",err.data.error,"error");
          }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
      }
  }
  
  const handleCloseModal = ()=>{
    setIsPaused(false);
    setOpen(false)
  }

    return (
        <section className=''>
            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative md:w-3/4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                        {
                            (product)?
                                (product.data['1'])?
                                <div className='flex justify-center'>
                                  <div className="w-full lg:w-2/2 mt-2">
                                    <div className="bg-white ">
                                    <section class="py-6"> 
                                      <div class="container mx-auto px-4">
                                        <nav class="flex">
                                          <ol role="list" class="flex items-center">
              
                                            <li class="text-left">
                                              <div class="flex items-center">
                                                <span class="mx-2 text-gray-400"></span>
                                                <div onClick={handleCloseModal} class="-m-1 text-gray-400 cursor-pointer hover:text-gray-700 hover:bg-gray-200 rounded-full p-3">
                                                  <XIcon className='h-4'/>
                                                </div>
                                              </div>
                                            </li>
                                          </ol>
                                        </nav>
              
                                        <div class="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
                                          <div class="lg:col-span-3 lg:row-end-1">
                                            <div class="lg:flex lg:items-start">
                                              <div class="lg:order-2 lg:ml-5">
                                                <div class="max-w-xl overflow-hidden rounded-lg">
                                                  <img class="h-full w-full max-w-full object-contain" src={`${(activeImage)? 'https://demoapi.autozedltd.com/uploads/'+activeImage : 'https://demoapi.autozedltd.com/uploads/'+product.data['0'].product.image}`} alt="" />
                                                </div>
                                              </div>
              
                                              <div class="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                                                <div class="flex flex-row items-start lg:flex-col">
                                                  {
                                                    (product.data['1'].product_images)?
                                                    product.data['1'].product_images.map(data => (
                                                        <button onClick={(e) => setActiveImage(data.image_name)} type="button" class={`${(activeImage == data.image_name)? 'border-2 border-gray-400' : ''} flex-0 aspect-square mb-3 h-20 w-28 overflow-hidden rounded-lg text-center`}>
                                                          <img class="h-full w-full object-contain" src={`https://demoapi.autozedltd.com/uploads/`+data.image_name} alt="" />
                                                        </button>
                                                      ))
                                                    :null
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
              
                                          <div class="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                                            <h1 class="text-gray-900 text-lg mt-3">{product.data['0'].product.name}</h1>
              
                                            <div class="mt-5 flex items-center border-b pb-4">
                                              <div class="flex items-center">
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                              </div>
                                              <p class="ml-2 text-sm font-medium text-gray-500">4 Reviews</p>
                                            </div>
              
                                            <h2 class="mt-3 text-xs uppercase text-gray-400">Product Information</h2>
                                            <div class="mt-3 flex select-none items-center gap-1">
                                              <div className="flex flex-col text-xs gap-y-2">
                                                <div className="flex items-center gap-x-6 justify-start">
                                                  <p className="mr-4 text-gray-600">Brand:</p>
                                                  <p className="text-gray-700">{(product.data['0'].product.brand != null)? product.data['0'].product.brand :(product.data['0'].product.custome_brand != null)? product.data['0'].product.custome_brand : 'None'}</p>
                                                </div>
                                                <div className="flex items-center gap-x-6 justify-start">
                                                  <p className="mr-4 text-gray-600">Category:</p>
                                                  <p className="text-gray-700">{product.data['0'].product.category}</p>
                                                </div>
                                              </div>
                                            </div>

                                            <h2 class="mt-4 text-sm text-gray-400">Product Description</h2>
                                            <div class="mt-3 flow-root text-xs text-gray-600">
                                              {product.data['0'].product.description}
                                            </div>
              
                                            <div class="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
                                              <div class="flex space-x-2 items-end">
                                                <h1 class="text-2xl text-red-500">ZK{numberWithCommas(parseInt(product.data['0'].product.price))}</h1>
                                                <span class="text-lg text-gray-500 line-through">K{numberWithCommas(parseInt(product.data['0'].product.old_price))}</span>
                                              </div>
              
                                              <button onClick={(e)=>addItemToCart(e,product.data['0'].product)} class="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-rose-600 bg-none px-2 py-1.5 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-rose-700">
                                                <ShoppingCartIcon className='h-5 mr-1'/>
                                                <div className='text-sm '>Add to cart</div>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    </div>
                                  </div>
                                </div>
                                :<div className='text-sm '>Add to cart</div>
                            :<div className='text-sm '>Add </div>
                            }
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
            <div>
                <div className="flex flex-col pt-2 pb-2 bg-white shadow-xs">
                {
                    (props.props.data.length > 0)?
                        <Swiper 
                            spaceBetween={0} 
                            centeredSlides={false} 
                            slidesPerView={(window.innerWidth < 500)? 1 :(window.innerWidth < 850)? 2 : 4}
                            autoplay={{
                                "delay": 3000,
                                "disableOnInteraction": false
                                }} 
                            navigation={false} 
                            className="mySwiper flex w-full">
                                {
                                    props.props.data.map(data => (
                                            <SwiperSlide className="object-cover pt-6">
                                               <li
                                                    className={`${isHovered && cardId == data.product_id ? 'transform scale-70 transition-all duration-500 shadow-md z-50' : ''} border rounded-t-lg rounded-b-md  bg-white`} key={''} onMouseEnter={(e)=>handleItemHover(data.product_id)} onMouseLeave={(e)=>handleItemLeave(data.product_id)} style={{ marginRight: '0px' }}
                                                >
                                                    <div class="">
                                                        <div>
                                                        <a class="block relative h-48 rounded overflow-hidden">
                                                        <img 
                                                            className="object-contain h-60 w-full rounded-t-md shodow-lg group-hover:opacity-50"
                                                            src={`https://demoapi.autozedltd.com/uploads/`+data.image} 
                                                            alt="Image" 
                                                        />
                                                        </a>
                                                            <div class="mt-4 rounded-b-md ml-1 mr-1">
                                                            <h2 class="flex items-center space-x-2 text-gray-600 title-font text-lg font-medium">
                                                                <div className='text-md text-red-500'>ZK {numberWithCommas(parseInt(data.price))}</div>
                                                                <div className='text-xs line-through'> ZK{(data.old_price)? numberWithCommas(parseInt(data.old_price)) : '00.00'}</div>
                                                              </h2>
                                                              <h2 class="text-gray-800 title-font text-md mt-3">{trancateText(data.name)}</h2>
                                                              <h2 class="text-gray-400 font-semibold text-xs mt-2">{(data.brand != null)? data.brand :(data.custome_brand != null)? data.custome_brand : 'None'}</h2>
                                                              <h2 class="text-gray-400 title-font text-xs font-medium">{data.category}</h2>
                                                              <div class="flex items-center">
                                                                  <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                                  <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                                  <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                                  <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                                  <svg aria-hidden="true" class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                                                  <p class="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">4.95 out of 5</p>
                                                              </div>
                                                              <p class="text-gray-500 title-font text-md font-medium pt-2 pb-2 h-12">{trancateText(data.description)}</p>
                                                          </div>
                                                          <div className='flex items-center justify-between pt-2 pb-4 pl-2 pr-6'>
                                                          <div onClick={(e)=>addItemToCart(e,data)} className="">
                                                            <button className="inline-flex w-full justify-center bg-rose-700 px-3 py-2 text-sm rounded-sm font-semibold text-white hover:bg-rose-600 uppercase">
                                                              <ShoppingCartIcon className='h-5 mr-1'/>
                                                              <div className='text-sm '>Add to cart</div>
                                                            </button>
                                                          </div>
                                                          <div onClick={(e)=>handleViewProduct(data.product_id)} className="text-gray-400 hover:text-gray-600 cursor-pointer">
                                                            <EyeIcon className='h-7'/>
                                                          </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </SwiperSlide>
                                        ))
                                }
                        </Swiper>
                    : null
                }

            </div>
            </div>
        </section>
    )
}

export default ProductsCarouselSliderView;
