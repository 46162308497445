import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import swal from 'sweetalert';
import { useCreateCategoryMutation, useDeleteCategoryMutation, useEditCategoryMutation, useGetAllCategoriesQuery, useGetCategoryByIdMutationMutation } from '../../Features/categoriesSlice';
import LoadingSpinner from '../LoadingSpinner';
// import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'


function AdminUserCategoriesView({props}) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const cancelButtonRef = useRef(null)

    const [input, setInput] = useState({
      name: '',
      id: '',
    })

    const [errors, setErrors] = useState({
        name: '',
    })
      
    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const handleCloseModal = (e)=>{
      setInput({...input,  
        name: '',
      })

      setErrors({...errors,  
        name: '',
      })
      setOpen(false)
    }

    const handleCloseEditModal = (e)=>{
      setInput({...input,  
        name: '',
      })

      setErrors({...errors,  
        name: '',
      })
      setOpenEditModal(false)
    }

    const {
      data: categories,
      isLoading: isLoadingCategories,
      isSuccess: isSuccessCategories,
      isError: isErrorCategories,
      error: errorCategories
    } = useGetAllCategoriesQuery()

    const [createCategory, {isLoading, isSuccess, isError}] = useCreateCategoryMutation()

    const handleSubmit = async(e)=>{
        e.preventDefault();
        try {
            const resp = await createCategory(input).unwrap()
            handleCloseModal()
            swal("Success",resp.message,"success");
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors,  
                  name: err.data.errors.name,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }           
    }

    const [editCategory, {isLoading: isLoadingEdit, isSuccess: isSuccessEdit}] = useEditCategoryMutation()

    const handleSubmitEdit = async(e)=>{
        e.preventDefault();
        try {
            const resp = await editCategory(input).unwrap()
            handleCloseEditModal()
            swal("Success",resp.message,"success");
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors,  
                  name: err.data.errors.name,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }           
    }

    const [deleteCategory, {isLoading: isLoadingDelete, isSuccess: isSuccessDelete}] = useDeleteCategoryMutation()

    const handleDelete = async(e, id)=>{
      if(window.confirm("Are you sure you want to delete this item?")){
        try {
            const resp = await deleteCategory(id).unwrap()
            swal("Success",resp.message,"success");
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
      }
    }

    const [getCategoryByIdMutation, {isLoading: isLoadingGetCategoryById, isSuccess: isSuccessGetCategoryById}] = useGetCategoryByIdMutationMutation()

    const handleEdit = async(e, id)=>{
      try {
          const resp = await getCategoryByIdMutation(id).unwrap()

          setInput({...input,  
            name: resp.data.name,
            id: resp.data.id,
          })
          setOpenEditModal(true)
        } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
            swal("Error",err.data.error,"error");
          }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
      }
    }

    return (
        <main className='w-full flex-grow'>
            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                            Create Category
                        </p>
                        <form autoComplete='off'>
                        <div class="p-6">
                            <label className="block text-sm text-gray-600" for="name">Name</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="name" 
                              id="name" 
                              value={input.name}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.name}</p>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={handleCloseModal}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            <Transition.Root show={openEditModal} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenEditModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                            Edit Category
                        </p>
                        <form autoComplete='off'>
                        <div class="p-6">
                            <label className="block text-sm text-gray-600" for="name">Name</label>
                            <input 
                              class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                              type="text"
                              placeholder=''
                              name="name" 
                              id="name" 
                              value={input.name}
                              onChange={handleChange}
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.name}</p>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                            onClick={handleSubmitEdit}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={handleCloseEditModal}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
            <div className='flex items-center justify-between'>
            <div className="w-full flex-grow">
                <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                    <h1 className="text-lg font-semibold">Categories</h1>
                </div>
            </div>
            </div>
            {
              (isSuccessCategories)?
                  <div className='flex justify-center'>
                    <div className="w-full lg:w-2/2 mt-4 p-4 rounded-md">
                      <div className="bg-white p-4 rounded-md">
                          <button
                            type="button"
                            class="py-2 px-4 sm:ml-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                            onClick={() => setOpen(true)}
                          >
                            Add Category
                          </button>
                          <table className="text-left w-full border-collapse mt-6"> 
                              <thead>
                                  <tr>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Name</th>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Actions</th>
                                  </tr>
                              </thead>
                              <tbody>
                                {
                                  (categories.data.length > 0)?
                                  categories.data.map(data => (
                                      <tr className="hover:bg-gray-50">
                                          <td className="py-1 px-6 border-b border-grey-light">{data.name}</td>
                                          <td className="py-1 px-6 border-b border-grey-light">
                                              <div className='flex items-center space-x-3'>
                                              <div onClick={(e)=>handleEdit(e,data.id)} className="py-1 cursor-pointer text-blue-600 font-light tracking-wider">Edit</div>
                                              <div onClick={(e)=>handleDelete(e,data.id)} className="py-1 cursor-pointer text-blue-600 font-light tracking-wider">Delete</div>
                                              </div>
                                          </td>
                                      </tr>
                                    ))
                                  :null
                                }
                              </tbody>
                          </table>
                      </div>
                    </div>
                  </div>
                :(isLoadingCategories)?
                  <LoadingSpinner />
                :(isErrorCategories)?
                <div className='flex justify-center'>
                  <div className="w-full lg:w-2/2 mt-4 p-4 rounded-md">
                    <div className="bg-white p-4 rounded-md">
                      <button
                          type="button"
                          class="py-2 px-4 sm:ml-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                          onClick={() => setOpen(true)}
                        >
                          Add Category
                        </button>
                        <p class="text-md sm:ml-4 mt-2 mb-2 bg-red-300 text-gray-800 rounded-md p-3 flex justify-center items-center">
                            <i class=""></i> No categories found
                        </p>
                          <table className="text-left w-full border-collapse mt-6"> 
                              <thead>
                                  <tr>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Name</th>
                                      <th className="py-4 px-6 bg-gray-lightest font-bold uppercase text-sm text-gray-dark border-b border-gray-light">Actions</th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr className="hover:bg-gray-50">
                                    <td className="py-2 px-6 border-b border-grey-light"></td>
                                    <td className="py-2 px-6 border-b border-grey-light">
                                        <div className='flex items-center space-x-3'>
                                        <div className="py-1 cursor-pointer text-blue-600 font-light tracking-wider"></div>
                                        <div className="py-1 cursor-pointer text-blue-600 font-light tracking-wider"></div>
                                        </div>
                                    </td>
                                </tr>
                              </tbody>
                          </table>
                      </div>
                    </div>
              </div>
              :null
            }
        </main>
    )
}

export default AdminUserCategoriesView;
