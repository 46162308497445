import { Link, Outlet } from "react-router-dom";
import { useState } from "react";
import Logo from "../Views/Logo";
import { HomeIcon, UserIcon, MenuIcon, UserCircleIcon} from '@heroicons/react/outline';
import { useLogoutMutation } from "../Features/usersSlice";
import { logOut } from "../Features/auth/authSlice";
import { useDispatch } from "react-redux";
import swal from 'sweetalert';
import { Menu } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import { useGetSettingsQuery } from "../Features/settingsSlice";

function UserLayout() {
  const dispatch = useDispatch()
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true)

  const [logout, {isLoading}] = useLogoutMutation();
  
  const {
    data: settings,
    isLoading: isLoadingSettings,
    isSuccess: isSuccessSettings,
    isError: isErrorSettings,
    error: errorTSettings
  } = useGetSettingsQuery()

  const logUserOut = async () =>{
    try {
      const resp = await logout().unwrap()
      dispatch(logOut())
  } catch (err) {
      if(err){
          swal("Error", err.message,"error")
          dispatch(logOut())
      }    
    }
  }

  return (
    <div className="bg-gray-50 flex">
        <aside className="relative bg-sidebar h-screen w-48 hidden sm:block shadow-xl bg-gray-900">
            <div className="flex justify-center p-6">
            {  
                (isSuccessSettings)?
                  <Link to="/">
                    <img 
                        className="object-fill w-60 rounded-t-md shodow-lg group-hover:opacity-50"
                        src={`https://api.autozedltd.com/uploads/`+settings.data.logo_image} 
                        alt="Image" 
                    />
                  </Link>
                :null
              }
            </div>
            <nav className="text-xs font-semibold uppercase pt-3">
                <Link to="/admin" className={`${(location.pathname == '/admin')? 'bg-gray-800 text-rose-600' : 'text-gray-500 hover:text-gray-300' } flex items-center py-4 pl-6`}>
                    <HomeIcon className="h-6 w-6 mr-2" />
                    <div className="">Dashboard</div>
                </Link>
                <Link to="/admin/orders" className={`${(location.pathname == '/admin/orders')? 'bg-gray-800 text-rose-600' : 'text-gray-500 hover:text-gray-300' }  flex items-center py-4 pl-6`}>
                    <HomeIcon className="h-6 w-6 mr-2" />
                    <div className="">Orders</div>
                </Link>
                <Link to="/admin/bookings" className={`${(location.pathname == '/admin/bookings')? 'bg-gray-800 text-rose-600' : 'text-gray-500 hover:text-gray-300' }  flex items-center py-4 pl-6`}>
                    <HomeIcon className="h-6 w-6 mr-2" />
                    <div className="">Bookings</div>
                </Link>
                <Link to="/admin/products" className={`${(location.pathname == '/admin/products')? 'bg-gray-800 text-rose-600' : 'text-gray-500 hover:text-gray-300' }  flex items-center py-4 pl-6`}>
                    <HomeIcon className="h-6 w-6 mr-2" />
                    <div className="">Products</div>
                </Link>
                <Link to="/admin/categories" className={`${(location.pathname == '/admin/categories')? 'bg-gray-800 text-rose-600' : 'text-gray-500 hover:text-gray-300' }  flex items-center py-4 pl-6`}>
                    <HomeIcon className="h-6 w-6 mr-2" />
                    <div className="">Categories</div>
                </Link>
                <Link to="/admin/top-categories" className={`${(location.pathname == '/admin/top-categories')? 'bg-gray-800 text-rose-600' : 'text-gray-500 hover:text-gray-300' }  flex items-center py-4 pl-6`}>
                    <HomeIcon className="h-6 w-6 mr-2" />
                    <div className="">Top Categories</div>
                </Link>
                <Link to="/admin/brands" className={`${(location.pathname == '/admin/brands')? 'bg-gray-800 text-rose-600' : 'text-gray-500 hover:text-gray-300' }  flex items-center py-4 pl-6`}>
                    <HomeIcon className="h-6 w-6 mr-2" />
                    <div className="">Brands</div>
                </Link>
                <Link to="/admin/shipping-locations" className={`${(location.pathname == '/admin/shipping-locations')? 'bg-gray-800 text-rose-600' : 'text-gray-500 hover:text-gray-300' }  flex items-center py-4 pl-6`}>
                    <HomeIcon className="h-6 w-6 mr-2" />
                    <div className="">Shipping</div>
                </Link>
            </nav>
            <Link to="/admin/settings" className="absolute w-full upgrade-btn bottom-0 bg-rose-700 hover:bg-rose-600 text-white flex items-center justify-center py-4">
                <HomeIcon className="h-5 w-5 mr-2 hover:text-gray-200" />
                Settings
            </Link>
        </aside>
        {/* Header */}
        <div className="w-full flex flex-col h-screen overflow-y-hidden">
            {/* Desktop header */}
            <header className="w-full items-center bg-white py-2 px-6 hidden sm:flex">
                <div className="w-1/2"></div>
                <Menu as="div" className='relative w-1/2 flex justify-end'>
                    <Menu.Button className="flex justify-center items-center z-10 w-12 h-12 rounded-full overflow-hidden border-4 border-gray-400 hover:border-gray-300 focus:border-gray-300 focus:outline-none">
                        <UserCircleIcon className="h-7 w-7 round-full text-gray-300"/>
                    </Menu.Button> 
                    <Menu.Items className="absolute w-32 bg-white rounded-lg shadow-lg py-2 mt-16">
                    <Menu.Item>
                        <div className='flex justify-center block hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                        <Link to="/admin/user-profile">
                            My profile
                        </Link>
                        </div>
                    </Menu.Item>
                    <Menu.Item>
                        <div onClick={logUserOut} className='flex justify-center block hover:bg-gray-100 cursor-pointer px-4 py-2 text-sm text-gray-700'>
                        Logout
                        </div>
                    </Menu.Item>
                    </Menu.Items>
                </Menu>
            </header>
            {/* Mobile header */}
            <header className="w-full bg-sidebar py-5 px-6 sm:hidden">
                <Menu as="div" className='w-full bg-sidebar py-5 px-6 sm:hidden'>
                    <div className="flex items-center justify-between">
                        <Link to="" className="text-white text-3xl font-semibold uppercase hover:text-gray-300">Admin</Link>
                        <Menu.Button className="text-white text-3xl focus:outline-none">
                            <MenuIcon className="h-7 w-7 round-full"/>
                        </Menu.Button> 
                    </div>
                    <Menu.Items className="flex flex-col pt-4">
                        <Menu.Item className="flex items-center active-nav-link text-white py-2 pl-4 nav-item">
                            <Link to="/user/agent-users" className="flex items-center active-nav-link text-white py-2 pl-4 nav-item">
                                <HomeIcon className="h-6 w-6 mr-3" />
                                Dashboard
                            </Link>
                        </Menu.Item>
                        <Menu.Item className="flex items-center active-nav-link text-white py-2 pl-4 nav-item">
                            <Link to="/user/agent-users" className="flex items-center active-nav-link text-white py-2 pl-4 nav-item">
                                <HomeIcon className="h-6 w-6 mr-3" />
                                Dashboard
                            </Link>
                        </Menu.Item>
                    </Menu.Items>
                </Menu>
            </header>
            <div className="w-full overflow-x-hidden border-t flex flex-col">
                <Outlet />
            </div>
        </div>
    </div>
  );
}

export default UserLayout;
