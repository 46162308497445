import { Route, Routes} from "react-router-dom";
import Layout from "./Components/Layout";
import RequireAuth from "./Components/RequireAuth";
import ClientUserLayout from "./Layouts/ClientUserLayout";
import AdminUserLayout from "./Layouts/AdminUserLayout";
import ClientHomeView from "./Views/App/ClientHomeView";
import LoginView from "./Views/LoginView";
import AddSystemRoleView from "./Views/user/AddSystemRoleView";
import AllUsersView from "./Views/user/AllUsersView";
import CurrentUserProfileView from "./Views/user/CurrentUserProfileView";
import EditSystemRoleView from "./Views/user/EditSystemRoleView";
import ManageClientRolesView from "./Views/user/ManageClientRolesView";
import ManageSystemRolesView from "./Views/user/ManageSystemRolesView";
import ManageUserClientRolesView from "./Views/user/ManageUserClientRolesView";
import ManageUserProfileView from "./Views/user/ManageUserProfileView";
import ManageUserRolesView from "./Views/user/ManageUserRolesView";
import UserProfileEditView from "./Views/user/UserProfileEditView";
import UserRegisterView from "./Views/UserRegisterView";
import HomeView from "./Views/App/HomeView";
import PublicAuth from "./Components/PublicAuth";
import UnauthorizedView from "./Views/UnauthorizedView";
import UndefinedRouteView from "./Views/UndefinedRouteView";
import AdminUserIndex from "./Views/user/AdminUserIndex";
import AdminUserCategoriesView from "./Views/user/AdminUserCategoriesView";
import AdminUserBrandsView from "./Views/user/AdminUserBrandsView";
import AdminUserProductsView from "./Views/user/AdminUserProductsView";
import AdminUserCreateProductsView from "./Views/user/AdminUserCreateProductsView";
import AdminUserProductView from "./Views/user/AdminUserProductView";
import AdminUserEditProductsView from "./Views/user/AdminUserEditProductsView";
import AdminUserTopCategoriesView from "./Views/user/AdminUserTopCategoriesView";
import ShopView from "./Views/App/ShopView";
import ShoppingCartView from "./Views/App/ShoppingCartView";
import AdminUserShppingLocationsView from "./Views/user/AdminUserShppingLocationsView";
import AdminUserOrdersView from "./Views/user/AdminUserOrdersView";
import AdminUserSettingsView from "./Views/user/AdminUserSettingsView";
import AdminUserOrderView from "./Views/user/AdminUserOrderView";
import AdminUserBookingsView from "./Views/user/AdminUserBookingsView";
import AdminUserManageBrandModelsView from "./Views/user/AdminUserManageBrandModelsView ";
import AdminUserBookingLocationsView from "./Views/user/AdminUserBookingLocationsView";
import AboutPageView from "./Views/App/AboutPageView";
import ContactPageView from "./Views/App/ContactPageView";
import AdminUserBookingView from "./Views/user/AdminUserBookingView";
import CheackoutReceiptView from "./Views/App/CheackoutReceiptView";
import BookingReceiptView from "./Views/App/BookingReceiptView";
import PrivacyPlicyView from "./Views/App/PrivacyPlicyView";
import RefundPrivacyPlicyView from "./Views/App/RefundPrivacyPlicyView";
import FAQView from "./Views/App/FAQView";
import UserProfileView from "./Views/user/UserProfileView";
import ViewProductView from "./Views/App/ViewProductView";
import BookingTransportChargeReceiptView from "./Views/App/BookingTransportChargeReceiptView";
import BookingServiceChargeReceiptView from "./Views/App/BookingServiceChargeReceiptView";
import BookingOnSiteReceiptView from "./Views/App/BookingOnSiteReceiptView";



function App() {

  return (
    <div>
      <Routes>
              {/* public route */}
          <Route element={ <PublicAuth /> }>
            <Route path="/" element={ <Layout />}>
              <Route path="/" element={ <HomeView /> } />
              <Route path="/shop/:search" element={ <ShopView /> } />
              <Route path="/view-product/:product_id" element={ <ViewProductView /> } />
              <Route path="/receipt/*" element={ <CheackoutReceiptView /> } />
              <Route path="/booking-on-site-receipt/:id" element={ <BookingOnSiteReceiptView /> } />
              <Route path="/booking-off-site-receipt/*" element={ <BookingReceiptView /> } />
              <Route path="/booking-transport-charge-receipt/*" element={ <BookingTransportChargeReceiptView /> } />
              <Route path="/booking-service-charge-receipt/*" element={ <BookingServiceChargeReceiptView /> } />
              {/* Cart */}
              <Route path="/shopping-cart" element={ <ShoppingCartView  /> } />

              <Route path="/user/register" element={ <UserRegisterView /> } />
              <Route path="/login" element={ <LoginView  /> } />
              <Route path="/about" element={ <AboutPageView  /> } />
              <Route path="/privacy-policy" element={ <PrivacyPlicyView  /> } />
              <Route path="/return-policy" element={ <RefundPrivacyPlicyView  /> } />
              <Route path="/faq" element={ <FAQView  /> } />
              <Route path="/support" element={ <ContactPageView  /> } />
            </Route>
          </Route>

               {/* User Private route */}
            <Route element={ <RequireAuth allowedRoles={["Admin"]} /> }>
              <Route path="/admin" element={ <AdminUserLayout  /> } >
                <Route path="/admin" element={ <AdminUserIndex  /> } />
                <Route path="/admin/settings" element={ <AdminUserSettingsView  /> } />
                {/* Categories */}
                <Route path="/admin/categories" element={ <AdminUserCategoriesView  /> } />
                {/* Top Categories */}
                <Route path="/admin/top-categories" element={ <AdminUserTopCategoriesView  /> } />
                {/* Brand */}
                <Route path="/admin/brands" element={ <AdminUserBrandsView  /> } />
                {/* Brand Models*/}
                <Route path="/admin/brand-models/:brand_id" element={ <AdminUserManageBrandModelsView  /> } />
                {/* Products */}
                <Route path="/admin/products" element={ <AdminUserProductsView  /> } />
                <Route path="/admin/product/:product_id" element={ <AdminUserProductView  /> } />
                <Route path="/admin/create-products" element={ <AdminUserCreateProductsView  /> } />
                <Route path="/admin/edit-product/:product_id" element={ <AdminUserEditProductsView  /> } />
                {/* Shipping locations */}
                <Route path="/admin/shipping-locations" element={ <AdminUserShppingLocationsView  /> } />
                {/* Booking locations */}
                <Route path="/admin/booking-locations" element={ <AdminUserBookingLocationsView  /> } />
                {/* Orders  */}
                <Route path="/admin/orders" element={ <AdminUserOrdersView  /> } />
                <Route path="/admin/view-order/:sale_id" element={ <AdminUserOrderView  /> } />
                {/* Bookings  */}
                <Route path="/admin/bookings" element={ <AdminUserBookingsView  /> } />
                <Route path="/admin/view-booking/:booking_id" element={ <AdminUserBookingView  /> } />


                <Route path="/admin/user-profile" element={ <UserProfileView  /> } />
                
                {/* <Route path="/user/agent-users" element={ <AllAgentUsersView  /> } />
                <Route path="/user/register-agent-user" element={ <AgentUserRegisterView  /> } />
                <Route path="/user/profile" element={ <CurrentUserProfileView  /> } />
                <Route path="/user/manage-user-profile/:user_id" element={ <ManageUserProfileView  /> } />
                <Route path="/user/manage-agent-user-profile/:user_id" element={ <ManageAgentUserProfileView  /> } />
                <Route path="/user/manage-user/roles/:user_id" element={ <ManageUserRolesView  /> } />
                <Route path="/user/user-profile-edit/:user_id" element={ <UserProfileEditView  /> } />
                <Route path="/user/agent-user-profile-edit/:user_id" element={ <AgentUserProfileEditView  /> } />
                <Route path="/user/sytems-roles-management" element={ <ManageSystemRolesView  /> } />
                <Route path="/user/edit-sytems-role/:role_id" element={ <EditSystemRoleView  /> } />
                <Route path="/user/add-sytems-role" element={ <AddSystemRoleView  /> } />
                <Route path="/user/client-roles-management" element={ <ManageClientRolesView  /> } />
                <Route path="/user/manage-user-client-roles/user-client-roles/:user_id" element={ <ManageUserClientRolesView  /> } /> */}

                {/* App Manage front end images */}
                {/* <Route path="/user/front-end-images" element={ <ManageFrontEndImagesView  /> } />
                <Route path="/user/upload-front-end-image" element={ <UploadFrontEndImageView  /> } />
                <Route path="/user/manage-front-end-image/:image_id" element={ <ManageFrontEndImageView  /> } />
                <Route path="/user/edit-front-end-image/:image_id" element={ <EditFrontEndImageView  /> } /> */}
              </Route>  
            </Route>
            
               {/* Client User Private route */}
            <Route element={ <RequireAuth allowedRoles={["Client"]} /> }>
              <Route path="/client" element={ <ClientUserLayout  /> } >
                <Route path="/client" element={ <ClientHomeView  /> } />
                <Route path="/client/user-profile" element={ <UserProfileView  /> } />
              </Route>  
            </Route>

            {/* unauthorizede route */}
            <Route path="/authentication/unauthorized" element={ <UnauthorizedView  /> } />

            {/* undefined routes */}
            <Route path="*" element={ <UndefinedRouteView  /> } />
      </Routes>
    </div>
  );
}

export default App;
