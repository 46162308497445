import { Link, useNavigate } from 'react-router-dom';
import Home from '../../HeaderLayouts/Home';
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useRecoilState } from 'recoil';
import { CartState } from '../../CartState';
import { useGetAllLocationsQuery, useGetLocationByIdMutationMutation } from '../../Features/shippingLocationsSlice';
import { useCreateSaleMutation, useValidateSaleMutation } from '../../Features/SalesSlice';
import swal from 'sweetalert';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';


function ShoppingCartView({props}) {
    const navigate = useNavigate();

    const [open, setOpen] = useState(false)
    const [cartItem, setCartItem] = useRecoilState(CartState)
    const [shipping, setShipping] = useState(null)
    const [allowSubmit, setAllowSubmit] = useState(false);
    const cancelButtonRef = useRef(null)
    
    const [input, setInput] = useState({
        coupon: '',
        shipping: '',
        mobile: '',
        paymentReference: '',
        first_name: '',
        last_name: '',
      })

    const [errors, setErrors] = useState({
        coupon: '',
        shipping: '',
        mobile: '',
        first_name: '',
        last_name: '',
    })

    const handleCloseModal = (e)=>{
        setInput({...input,  
          mobile: '',
            first_name: '',
            last_name: '',
        })
  
        setErrors({...errors,  
          mobile: '',
            first_name: '',
            last_name: '',
        })
        setOpen(false)
      }

      const {
        data: locations,
        isLoading: isLoadingLocations,
        isSuccess: isSuccessLocations,
        isError: isErrorLocations,
        error: errorLocations
      } = useGetAllLocationsQuery()

    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    useEffect(()=>{
      setInput({...input,  
        paymentReference: refId(),
      })
      window.scrollTo(0, 0)
      shippingExists()
      return () =>{
      }
    },[]);

    const [validateSale,{isLoading: isLoadingValidateSale}] = useValidateSaleMutation();

    const handleValidateSubmit = async(e)=>{
        e.preventDefault();
        let data = {
          products: cartItem, 
          fname: input.first_name,
          lname: input.last_name,
          phone: input.mobile,
          transaction_reference: input.paymentReference,
          coupon: input.coupon,
          shipping_id: input.shipping,
          total_charge: totalPrice(),
          shipping_charge: shipping,
        }
        try {
          const resp = await validateSale(data).unwrap()          
          handleSubmit()
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors,  
                  first_name: err.data.errors.fname,
                  lname: err.data.errors.last_name,
                  mobile: err.data.errors.phone,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }           
    }

    const [createSale,{isLoading: isLoadingSale}] = useCreateSaleMutation();

    const handleSubmit = async(e)=>{
        let data = {
          products: cartItem, 
          fname: input.first_name,
          lname: input.last_name,
          phone: input.mobile,
          transaction_reference: input.paymentReference,
          coupon: input.coupon,
          shipping_id: input.shipping,
          total_charge: totalPrice(),
          shipping_charge: shipping,
        }
        try {
            const resp = await createSale(data).unwrap()
            setAllowSubmit(true)
            document.getElementById('payment_form').submit();
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors,  
                  name: err.data.errors.name,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }           
    }

    const [getLocationByIdMutation,{isLoading: isLoadingGetLocation}] = useGetLocationByIdMutationMutation();

    const handleDeliveryLocationChange = async(e)=>{
        try {   
          let value = e.target.value

          const resp = await getLocationByIdMutation(value).unwrap()
          const id = resp.data.id
          const price = resp.data.charge

          setInput({...input, [e.target.name]: id})
          setShipping(price)
        } catch (error) {
          swal("Error",error.data.error,"error");
        }

    }
    
    const totalPrice = ()=>{
        let totalPrice = 0;
        let checkShipping = false;
        cartItem.forEach(pro => totalPrice += (pro.price * pro.total_quantity))
        totalPrice += (shipping)? shipping : 0;
        return totalPrice;
      }

    const shippingExists = ()=>{
      let checkShipping = false;
        // cartItem.every(pro => {
        //   if(pro.has_shipping){
        //     checkShipping += 1;
        //     return false;
        //   }
        // })
      //   for(let i=0; cartItem.length>0; i++){
      //       if(cartItem.has_shipping){
      //       checkShipping = true;
      //       break;
      //   }
      // }
        return checkShipping;
    }

    const subtotalPrice = ()=>{
        let totalPrice = 0;
        cartItem.forEach(pro => totalPrice += (pro.price * pro.total_quantity))
        return totalPrice;
      }

      const removeItemFromCart = (e, productIdToRemove) => {
        setCartItem((prevState) => {
          return prevState.filter((item) => item.product_id !== productIdToRemove);
        });
    
        toast('Item removed from cart');
      };

    const increamentQuantity = (e, product)=>{
        setCartItem(prevState => {
        return prevState.map((item)=>{
            return item.product_id === product.product_id ? {...item, total_quantity: (item.total_quantity)? item.total_quantity + 1 : 1} : item
        })
        })
    }

    const decreamentQuantity = (e, product)=>{
        setCartItem(prevState => {
        return prevState.map((item)=>{
            return item.product_id === product.product_id ? {...item, total_quantity: (item.total_quantity)? (item.total_quantity < 0)? 1 : item.total_quantity - 1 : 1} : item
        })
        })
    }

    const numberWithCommas = (x)=>{
        if(x !==null){
          const formatedNumber = x.toLocaleString('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  });
          return formatedNumber;
        }
        return null;
    }

    const refId = (length) => {
      var result = '';
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    };

    const openModal = ()=>{
      if(shipping){
        return setOpen(true)
      }
      swal("Error","Provide delivery location","error");
    }

    return ( 
        <main className='w-full bg-gray-50 flex-grow'>
            <Home />

            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        <p className="text-xl p-3 flex w-full items-center justify-center border-b border-gray-200">
                            Provide Checkout Details
                        </p>
                        <form 
                          id="payment_form" 
                          action={allowSubmit ? 'https://paymentservices.probasegroup.com/pbs/payments' : ''} 
                          method="post" 
                          autoComplete='off'
                        >
                        <div class="pl-6 pr-6 pb-2 pt-4">
                            <label className="block text-sm text-gray-600" for="phone">Phone number</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="number"
                              placeholder=''
                              name="mobile" 
                              id="mobile" 
                              value={input.mobile}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.mobile}</p>
                        </div>
                        <div class="pl-6 pr-6 pb-2">
                            <label className="block text-sm text-gray-600" for="fname">First Name</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="text"
                              placeholder=''
                              name="first_name" 
                              id="first_name" 
                              value={input.first_name}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.first_name}</p>
                        </div>
                        <div class="pl-6 pr-6">
                            <label className="block text-sm text-gray-600" for="lname">Last Name</label>
                            <input 
                              class="w-full px-5 py-2 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                              type="text"
                              placeholder=''
                              name="last_name" 
                              id="last_name" 
                              value={input.last_name}
                              onChange={handleChange}
                              required
                          />
                          <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.last_name}</p>
                        </div>
                        <input type="hidden"  id="redirectUrl"  name="redirectUrl" value="https://autozedltd.com/receipt/" /> 
                        <input type="hidden" id="system_id" name="systemId" value="AutoZed-102300010" />
                        <input type="hidden" name="responseMethod" value="GET" />
                        <input type="hidden" name="sourceInstitution" value="AutoZed" /> 
                        <input type="hidden" id="external_ref"  name="paymentReference" onChange={handleChange} value={input.paymentReference} />
                        <input type="hidden" id="amount"  name="amount" value={totalPrice()}/> 
                        <input type="hidden"  id="paymentType"  name="paymentType" value="Purchase of goods" />
                        <input type="hidden"  id="customerType"  name="customerType" value="cooperate" />
                        <input type="hidden" id="company_name" name="companyName" value="AutoZed" />
                        <input type="hidden" id="tpin"  name="tpin" value="98765434554" />
                        <input type="hidden" id="description"  name="paymentDescription" value="Purchase of goods"/>
                        <input type="hidden" id="email" name="email" value="admin@autozedltd.com" />
                        <input type="hidden"  name="password" value="Njz-rQ4{FvX7hnUwcaTE"/>

                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            className="inline-flex w-full justify-center rounded-md bg-rose-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-600 sm:ml-3 sm:w-auto"
                            onClick={handleValidateSubmit}
                          >
                            Checkout
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={handleCloseModal}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            <div class="min-h-screen pt-20 pb-12">
                <h1 class="mb-10 text-center text-2xl font-bold">Cart Items</h1>
                {
                    (cartItem.length > 0)?
                    <div class="mx-auto justify-center px-6 md:flex md:space-x-6 xl:px-0">
                        <div class="bg-white rounded-lg md:w-2/3 pl-5">
                            {
                                cartItem.map((data)=>(
                                        <div class="justify-between rounded-t-lg bg-white p-4 border-b border-gray-200 sm:flex sm:justify-start">
                                            <img src={`https://demoapi.autozedltd.com/uploads/`+data.image} alt="product-image" class="w-30 h-20  object-contain rounded-lg sm:w-40" />
                                            <div class="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                                                <div class="mt-5 sm:mt-0">
                                                    <h2 class="text-md font-semibold text-gray-900">{data.name}</h2>
                                                    <p class="mt-1 text-xs text-gray-700">Brand: {data.brand}</p>
                                                    <p class="mt-1 text-xs text-gray-700">Price: K{data.price}</p>
                                                    <p class={`mt-1 text-xs text-gray-700 flex items-center gap-x-1 ${(data.has_shipping)? 'text-green-500' : `text-red-500`}`}><div>Shipping:</div>  {(data.has_shipping)? <CheckIcon className='h-4' /> : <XIcon className='h-4'/>}</p>
                                                </div>
                                                <div class="mt-4 flex justify-between im sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                                                <div class="flex items-center border-gray-100">
                                                    <span onClick={(e)=>decreamentQuantity(e,data)} class="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-rose-600 hover:text-blue-50"> - </span>
                                                    <input class="h-8 w-10 border bg-white text-center text-gray-900 text-xs outline-none" type="text" value={data.total_quantity} />
                                                    <span onClick={(e)=>increamentQuantity(e,data)} class="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-rose-600 hover:text-blue-50"> + </span>
                                                </div>
                                                <div class="flex items-center space-x-4">
                                                    <p class="text-xs font-semibold">Total: ZMW {numberWithCommas(data.total_quantity * data.price)}</p>
                                                    <svg onClick={(e)=>removeItemFromCart(e,data.product_id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 cursor-pointer duration-150 hover:text-red-500">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                ))
                            }
                            <div class="sm:ml-4 sm:flex sm:w-full pt-4 pb-2 sm:justify-between">
                                <div class="mt-5 sm:mt-0 flex flex-wrap justify-between items-center pt-4">
                                    <div class="uppercase mb-2 md:mb-0 font-semibold cursor-pointer text-white rounded-md bg-rose-600 hover:bg-rose-700 p-3">
                                        Coupon code
                                    </div>
                                    <div class="md:ml-4">
                                     <input 
                                        className="w-full px-5 py-3 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded"
                                        type="text"
                                        placeholder=''
                                        name="coupon" 
                                        id="coupon" 
                                        value={input.coupon}
                                        onChange={handleChange}
                                    />
                                    </div>
                                </div>
                                {
                                    (isSuccessLocations)?
                                      <div class="mt-4 flex md:justify-end im sm:space-y-6 sm:mt-0 sm:block">
                                          <div class="pt-2 pb-2 pr-6 md:pl-6">
                                              <label for="category_id" class="block text-sm text-gray-600">Shipping destination</label>
                                              <div className="mt-2">
                                                  <select 
                                                  value={input.shipping} 
                                                  onChange={handleDeliveryLocationChange} 
                                                  name="shipping" 
                                                  className="w-full px-5 py-3 outline-none border-none focus:ring-rose-600 bg-gray-100 rounded">
                                                  <option>Select</option>
                                                  {
                                                      (locations.data.length > 0)?
                                                      locations.data.map(data => (
                                                          <option value={data.id}>{data.name} K{data.charge}</option>
                                                      ))
                                                      :null
                                                  }
                                                  </select>
                                              </div>
                                              {/* <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.category}</p> */}
                                          </div>
                                      </div>
                                    :null
                                }
                            </div>
                        </div>
                            <div class="mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-1/3">
                                <div class="mb-2 flex justify-start text-2xl font-bold">
                                <p class="text-gray-900">Cart Totals</p>
                                </div>
                                <div class="mb-2 flex justify-between">
                                <p class="text-gray-700">Subtotal</p>
                                <p class="text-gray-700">K{numberWithCommas(subtotalPrice())}</p>
                                </div>
                                <div class="flex justify-between">
                                <p class="text-gray-700">Shipping</p>
                                <p class="text-gray-700">K{(shipping)? numberWithCommas(shipping) : "00.00"}</p>
                                </div>
                                <hr class="my-4" />
                                <div class="flex justify-between">
                                <p class="text-lg font-bold">Total</p>
                                <div class="">
                                    <p class="mb-1 text-lg font-bold">ZMW {numberWithCommas(totalPrice())}</p>
                                    <p class="text-sm text-gray-700">including VAT</p>
                                </div>
                                </div>
                                <button onClick={openModal}  class="mt-6 w-full rounded-md bg-rose-700 py-1.5 font-medium text-blue-50 hover:bg-rose-600">Check out</button>
                            </div>
                            </div>
                    :
                        <div className='pl-20 pr-20'>
                          <div className='p-5 flex justify-center items-center bg-red-300 rounded-md'>Your cart is empty</div>
                        </div>
                }
            </div>
        </main>
    )
}

export default ShoppingCartView;
