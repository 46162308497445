import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FileUploader } from "react-drag-drop-files";
import swal from 'sweetalert';
import LoadingSpinner from '../LoadingSpinner';
import { useEditBrandImageMutation, } from '../../Features/brandsSlice';
import { useCreateTopCategoryMutation, useDeleteTopCategoryMutation, useEditTopCategoryImageMutation, useEditTopCategoryMutation, useGetAllTopCategoriesQuery, useGetTopCategoryByIdMutationMutation } from '../../Features/topCategoriesSlice';
import { useGetAllCategoriesQuery } from '../../Features/categoriesSlice';


function AdminUserTopCategoriesView({props}) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openEditImageModal, setOpenEditImageModal] = useState(false)
    const cancelButtonRef = useRef(null)

    const [file, setFile] = useState(null);
    const fileTypes = ["JPG", "PNG", "GIF"];

    const getBase64 = (file) => {
      return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
  
        // Convert the file to base64 text
        reader.readAsDataURL(file);
  
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          baseURL = reader.result;
          resolve(baseURL);
        };
      });
    };

    const handleFileChange = async(file) => {
      const base64 = await getBase64(file[0])
      setFile(file);
      setInput({...input,  
        image: base64,
      })
    };

    const [input, setInput] = useState({
      category_id: '',
      item_1: '',
      item_2: '',
      item_3: '',
      item_4: '',
      image: '',
      id: '',
    })

    const [errors, setErrors] = useState({
      category_id: '',
      item_1: '',
      item_2: '',
      item_3: '',
      item_4: '',
      image: '',
    })
      
    const handleChange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value})
    }

    const handleFile = (file) =>{
      setInput({...input, brand_image: file.base64})
    }

    const handleCloseModal = (e)=>{
      setInput({...input,  
        category_id: '',
        item_1: '',
        item_2: '',
        item_3: '',
        item_4: '',
        image: '',
      })

      setErrors({...errors,  
        category_id: '',
        item_1: '',
        item_2: '',
        item_3: '',
        item_4: '',
        image: '',
        id: '',
      })
      setOpen(false)
      setFile(null);
    }

    const handleCloseEditModal = (e)=>{
      setInput({...input,  
        category_id: '',
        item_1: '',
        item_2: '',
        item_3: '',
        item_4: '',
        image: '',
      })

      setErrors({...errors,  
        category_id: '',
        item_1: '',
        item_2: '',
        item_3: '',
        item_4: '',
        image: '',
      })
      setOpenEditModal(false)
      setFile(null); 
    }

    const handleCloseEditImageModal = (e)=>{
      setInput({...input,  
        category_id: '',
        item_1: '',
        item_2: '',
        item_3: '',
        item_4: '',
        image: '',
      })

      setErrors({...errors,  
        category_id: '',
        item_1: '',
        item_2: '',
        item_3: '',
        item_4: '',
        image: '',
        id: ''
      })
      setOpenEditImageModal(false)
      setFile(null); 
    }

    const {
      data: top_categories,
      isLoading: isLoadingTopCategories,
      isSuccess: isSuccessTopCategories,
      isError: isErrorTopCategories,
      error: errorTopCategories
    } = useGetAllTopCategoriesQuery()

    const {
      data: categories,
      isLoading: isLoadingCategories,
      isSuccess: isSuccessCategories,
      isError: isErrorCategories,
      error: errorCategories
    } = useGetAllCategoriesQuery()

    const [createTopCategory, {isLoading, isSuccess, isError}] = useCreateTopCategoryMutation()

    const handleSubmit = async(e)=>{
        e.preventDefault();
        // return console.log(input.brand_image)
        try {
            const resp = await createTopCategory(input).unwrap()
            handleCloseModal()
            swal("Success",resp.message,"success");
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors,  
                  category_id: err.data.errors.category_id,
                  item_1: err.data.errors.item_1,
                  item_2: err.data.errors.item_2,
                  item_3: err.data.errors.item_3,
                  item_4: err.data.errors.item_4,
                  image: err.data.errors.image,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }           
    }

    const [editTopCategory, {isLoading: isLoadingEdit, isSuccess: isSuccessEdit}] = useEditTopCategoryMutation()

    const handleSubmitEdit = async(e)=>{
        e.preventDefault();
        try {
            const resp = await editTopCategory(input).unwrap()
            handleCloseEditModal()
            swal("Success",resp.message,"success");
        } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
                setErrors({...errors,  
                  category_id: err.data.errors.category_id,
                  item_1: err.data.errors.item_1,
                  item_2: err.data.errors.item_2,
                  item_3: err.data.errors.item_3,
                  item_4: err.data.errors.item_4,
                  image: err.data.errors.image,
                })
            }else if (err.data.hasOwnProperty('error') == true){
                swal("Error",err.data.error,"error");
            }
        }           
    }

    const [editTopCategoryImage, {isLoading: isLoadingEditImage, isSuccess: isSuccessEditImage}] = useEditTopCategoryImageMutation()

    const handleSubmitEditImage = async(e)=>{
      e.preventDefault();
      try {
          const resp = await editTopCategoryImage(input).unwrap()
          handleCloseEditImageModal()
          swal("Success",resp.message,"success");
      } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
              setErrors({...errors,  
                image: err.data.errors.image,
              })
          }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
          }
      }           
  }

    const [deleteTopCategory, {isLoading: isLoadingDelete, isSuccess: isSuccessDelete}] = useDeleteTopCategoryMutation()

    const handleDelete = async(e, id)=>{
      if(window.confirm("Are you sure you want to delete this item?")){
        try {
            const resp = await deleteTopCategory(id).unwrap()
            swal("Success",resp.message,"success");
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
      }
    }

    const [getTopCategoryByIdMutation, {isLoading: isLoadingGetCategoryById, isSuccess: isSuccessGetCategoryById}] = useGetTopCategoryByIdMutationMutation()

    const handleEdit = async(e, id)=>{
      try {
          const resp = await getTopCategoryByIdMutation(id).unwrap()

          setInput({...input,  
            category_id: resp.data.top_category_id,
            item_1: resp.data.item_1,
            item_2: resp.data.item_2,
            item_3: resp.data.item_3,
            item_4: resp.data.item_4,
            id: resp.data.top_category_id,
          })
          setOpenEditModal(true)
        } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
            swal("Error",err.data.error,"error");
          }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
      }
    }

    const handleEditImage = async(e, id)=>{
      try {
          const resp = await getTopCategoryByIdMutation(id).unwrap()

          setInput({...input,  
            image: [],
            id: resp.data.top_category_id,
          })
          setOpenEditImageModal(true)
        } catch (err) {
          if(err.data.hasOwnProperty('errors') == true){
            swal("Error",err.data.error,"error");
          }else if (err.data.hasOwnProperty('error') == true){
            swal("Error",err.data.error,"error");
          }
      }
    }

    return (
        <main className='w-full flex-grow p-6'>
          {
            (isSuccessCategories)?
              <div>
                <Transition.Root show={open} as={Fragment}>
                  <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                                Create Top Category
                            </p>
                            <form autoComplete='off'>
                            <div class="spt-2 pb-2 pr-6 pl-6">
                              <label for="category_id" class="block text-sm text-gray-600">Category</label>
                              <div class="mt-2">
                                <select id="category_id" value={input.category_id} onChange={handleChange} name="category_id" class="w-full px-5 py-3 outline-none bg-gray-200 rounded">
                                  <option>Select category</option>
                                  {
                                    (categories.data.length > 0)?
                                      categories.data.map(data => (
                                        <option value={data.id}>{data.name}</option>
                                      ))
                                    :null
                                  }
                                </select>
                              </div>
                              <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.category}</p>
                            </div>
                            <div class="pt-2 pb-2 pr-6 pl-6">
                                <label className="block text-sm text-gray-600" for="item_1">Frist Item</label>
                                <input 
                                  class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                                  type="text"
                                  placeholder=''
                                  name="item_1" 
                                  id="item_1" 
                                  value={input.item_1}
                                  onChange={handleChange}
                              />
                              <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.item_1}</p>
                            </div>
                            <div class="pt-2 pb-2 pr-6 pl-6">
                                <label className="block text-sm text-gray-600" for="item_2">Second Item</label>
                                <input 
                                  class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                                  type="text"
                                  placeholder=''
                                  name="item_2" 
                                  id="item_2" 
                                  value={input.item_2}
                                  onChange={handleChange}
                              />
                              <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.item_2}</p>
                            </div>
                            <div class="pt-2 pb-2 pr-6 pl-6">
                                <label className="block text-sm text-gray-600" for="item_3">Third Item</label>
                                <input 
                                  class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                                  type="text"
                                  placeholder=''
                                  name="item_3" 
                                  id="item_3" 
                                  value={input.item_3}
                                  onChange={handleChange}
                              />
                              <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.item_3}</p>
                            </div>
                            <div class="pt-2 pb-2 pr-6 pl-6">
                                <label className="block text-sm text-gray-600" for="item_4">Fourth Item</label>
                                <input 
                                  class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                                  type="text"
                                  placeholder=''
                                  name="item_4" 
                                  id="item_4" 
                                  value={input.item_4}
                                  onChange={handleChange}
                              />
                              <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.item_4}</p>
                            </div>
                            <div class="p-6">
                                  <label className="block text-sm text-gray-600" for="name">Image</label>
                                  <FileUploader
                                    multiple={true}
                                    handleChange={handleFileChange}
                                    name="file"
                                    types={fileTypes}
                                  />
                                  <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>
                              <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.name}</p>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                              <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                                onClick={handleSubmit}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                onClick={handleCloseModal}
                                ref={cancelButtonRef}
                              >
                                Cancel
                              </button>
                            </div>
                            </form>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>

                <Transition.Root show={openEditModal} as={Fragment}>
                  <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenEditModal}>
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                                Edit Top Category
                            </p>
                            <form autoComplete='off'>
                            <div class="spt-2 pb-2 pr-6 pl-6">
                              <label for="category_id" class="block text-sm text-gray-600">Category</label>
                              <div class="mt-2">
                                <select id="category_id" value={input.category_id} onChange={handleChange} name="category_id" class="w-full px-5 py-3 outline-none bg-gray-200 rounded">
                                  <option>Select category</option>
                                  {
                                    (categories.data.length > 0)?
                                      categories.data.map(data => (
                                        <option value={data.id}>{data.name}</option>
                                      ))
                                    :null
                                  }
                                </select>
                              </div>
                              <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.category}</p>
                            </div>
                            <div class="pt-2 pb-2 pr-6 pl-6">
                                <label className="block text-sm text-gray-600" for="item_1">Frist Item</label>
                                <input 
                                  class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                                  type="text"
                                  placeholder=''
                                  name="item_1" 
                                  id="item_1" 
                                  value={input.item_1}
                                  onChange={handleChange}
                              />
                              <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.item_1}</p>
                            </div>
                            <div class="pt-2 pb-2 pr-6 pl-6">
                                <label className="block text-sm text-gray-600" for="item_2">Second Item</label>
                                <input 
                                  class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                                  type="text"
                                  placeholder=''
                                  name="item_2" 
                                  id="item_2" 
                                  value={input.item_2}
                                  onChange={handleChange}
                              />
                              <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.item_2}</p>
                            </div>
                            <div class="pt-2 pb-2 pr-6 pl-6">
                                <label className="block text-sm text-gray-600" for="item_3">Third Item</label>
                                <input 
                                  class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                                  type="text"
                                  placeholder=''
                                  name="item_3" 
                                  id="item_3" 
                                  value={input.item_3}
                                  onChange={handleChange}
                              />
                              <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.item_3}</p>
                            </div>
                            <div class="pt-2 pb-2 pr-6 pl-6">
                                <label className="block text-sm text-gray-600" for="item_4">Fourth Item</label>
                                <input 
                                  class="w-full px-5 py-3 outline-none bg-gray-200 rounded"
                                  type="text"
                                  placeholder=''
                                  name="item_4" 
                                  id="item_4" 
                                  value={input.item_4}
                                  onChange={handleChange}
                              />
                              <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.item_4}</p>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                              <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                                onClick={handleSubmitEdit}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                onClick={handleCloseEditModal}
                                ref={cancelButtonRef}
                              >
                                Cancel
                              </button>
                            </div>
                            </form>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>

                <Transition.Root show={openEditImageModal} as={Fragment}>
                  <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenEditImageModal}>
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <p className="text-xl p-3 flex items-center justify-center border-b border-gray-200">
                                Edit Category Image
                            </p>
                            <form autoComplete='off'>
                              <div class="p-6">
                                    <label className="block text-sm text-gray-600" for="name">Image</label>
                                    <FileUploader
                                      multiple={true}
                                      handleChange={handleFileChange}
                                      name="file"
                                      types={fileTypes}
                                    />
                                    <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p>
                                <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.name}</p>
                              </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                              <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto"
                                onClick={handleSubmitEditImage}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                onClick={handleCloseEditImageModal}
                                ref={cancelButtonRef}
                              >
                                Cancel
                              </button>
                            </div>
                            </form>
                          </Dialog.Panel>
                        </Transition.Child>
                      </div>
                    </div>
                  </Dialog>
                </Transition.Root>
              </div>
            :isLoadingCategories?
              <LoadingSpinner />
            :isErrorCategories?
              <div>failed</div>
            :null
          }

            <div className='flex items-center justify-between pb-2'>
                <h1 className="text-lg text-gray-800 uppercase">Top Categories</h1>
                <button
                  type="button"
                  className="inline-flex w-32 justify-center rounded-md bg-rose-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-b600 sm:ml-3 sm:w-32"
                  onClick={() => setOpen(true)}
                >
                  Add
                </button>
            </div>
            {
              (isSuccessTopCategories)?
                    <div className=''>
                      <section className="text-gray-600 body-font">
                            <div className="container md:px-2 py-10 mx-auto">
                            <div className="flex flex-col">
                                <div className="flex flex-wrap items-center  rounded-md">
                                {
                                    (top_categories.data.length > 0)?
                                    top_categories.data.map(data => (
                                        <div className="flex md:w-1/3 bg-gray-50 space-x-10 pb-1 pt-1 pl-6 bpr-6 rounded-sm border-b border-gray-200">
                                            <img 
                                                className="object-contain h-40 w-40 rounded-t-md shodow-lg group-hover:opacity-50"
                                                src={`https://demoapi.autozedltd.com/uploads/`+data.image} 
                                                alt="Image" 
                                            />
                                            <div className="pl-6">
                                                <Link to={`/shop/${data.name}`} className="text-gray-900 font-bold text-md cursor-pointer title-font mb-2">{data.name}</Link>
                                                <div className="leading-relaxed text-base">
                                                    <p className='text-gray-600 text-sm hover:text-yellow-600 pb-1'>{data.item_1}</p>
                                                    <p className='text-gray-600 text-sm hover:text-yellow-600 pb-1'>{data.item_2}</p>
                                                    <p className='text-gray-600 text-sm hover:text-yellow-600 pb-1'>{data.item_3}</p>
                                                    <p className='text-gray-600 text-sm hover:text-yellow-600 pb-1'>{data.item_4}</p>
                                                </div>
                                                <div class="pt-4">
                                                  <div class="flex items-center flex-wrap ">
                                                    <div className="text-xs">
                                                        <div className='flex items-center space-x-2'>
                                                          <div onClick={(e)=>handleEdit(e,data.top_category_id)} className="py-1 cursor-pointer text-blue-600 hover:text-blue-500 font-light tracking-wider">Edit</div>
                                                          <div onClick={(e)=>handleEditImage(e,data.top_category_id)} className="py-1 cursor-pointer text-blue-600 hover:text-blue-500 font-light tracking-wider">Image</div>
                                                          <div onClick={(e)=>handleDelete(e,data.top_category_id)} className="py-1 cursor-pointer text-blue-600 hover:text-blue-500 font-light tracking-wider">Delete</div>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                    ))
                                    :null
                                }
                                </div>
                            </div>
                            </div>
                      </section>
                      {/* <div className="w-full lg:w-2/2 mt-2">
                        <div className="">
                          <section className="text-gray-600 body-font">
                            <div className="container md:px-2 py-24 mx-auto">
                              <div className="flex flex-wrap items-center justify-between rounded-md">
                              {
                                (top_categories.data.length > 0)?
                                  top_categories.data.map(data => (
                                    <div className='flex flex-col bg-gray-50 mb-6'>
                                      <div className="flex bg-gray-50 space-x-10 pb-1 pt-1 pl-6 bpr-6 rounded-sm border-b border-gray-200">
                                        <div className="pl-6">
                                          <h2 className="text-gray-900 font-bold text-lg cursor-pointer title-font mb-2">{data.name}</h2>
                                          <div className="leading-relaxed text-base">
                                            <p className='text-gray-600 text-md hover:text-yellow-600 pb-1'>{data.item_1}</p>
                                            <p className='text-gray-600 text-md hover:text-yellow-600 pb-1'>{data.item_2}</p>
                                            <p className='text-gray-600 text-md hover:text-yellow-600 pb-1'>{data.item_3}</p>
                                            <p className='text-gray-600 text-md hover:text-yellow-600 pb-1'>{data.item_4}</p>
                                          </div>
                                        </div>
                                        <div className="h-40 w-40 pr-6 inline-flex items-center justify-center mb-4 flex-shrink-0">
                                          <img 
                                              className="object-fill h-40 w-40 rounded-t-md shodow-lg group-hover:opacity-50"
                                              src={`http://localhost:8000/uploads/`+data.image} 
                                              alt="Image" 
                                          />
                                        </div>
                                      </div>
                                          <div class="p-4">
                                            <div class="flex items-center flex-wrap ">
                                            <div className="">
                                                <div className='flex items-center space-x-3'>
                                                  <div onClick={(e)=>handleEdit(e,data.top_category_id)} className="py-1 cursor-pointer text-blue-600 font-light tracking-wider">Edit</div>
                                                  <div onClick={(e)=>handleEditImage(e,data.top_category_id)} className="py-1 cursor-pointer text-blue-600 font-light tracking-wider">Edit Image</div>
                                                  <div onClick={(e)=>handleDelete(e,data.top_category_id)} className="py-1 cursor-pointer text-blue-600 font-light tracking-wider">Delete</div>
                                                </div>
                                            </div>
                                            </div>
                                          </div>
                                    </div>
                                  ))
                                :null
                              }
                              </div>
                            </div>
                          </section>
                        </div>
                      </div> */}
                    </div>
                :(isLoadingTopCategories)?
                  <LoadingSpinner />
                :(isErrorTopCategories)?
                  <div>
                    failed
                  </div>
              :null
            }
        </main>
    )
}

export default AdminUserTopCategoriesView;
