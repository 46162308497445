import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import {useGetAllBrandsQuery, } from '../../Features/brandsSlice';
import { useGetAllProductsQuery, useGetProductByIdMutationMutation, useSeachProductsMutation } from '../../Features/productsSlice';
import Home from '../../HeaderLayouts/Home';
import { useGetAllCategoriesQuery } from '../../Features/categoriesSlice';
import toast from 'react-hot-toast';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { useRecoilState } from 'recoil';
import { CartState } from '../../CartState';
import { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, EyeIcon } from '@heroicons/react/outline';
import { useNextPageMutation } from '../../Features/SalesSlice';
import swal from 'sweetalert';
import { ShoppingCartIcon, XIcon } from '@heroicons/react/solid';


function ShopView({props}) {
    const navigate = useNavigate();
    const { search } = useParams();

    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    const [isHovered, setIsHovered] = useState(false);
    const [cardId, setCardId] = useState(false);
    const [searchData, setSearchData] = useState(null);
    const [page, setPage] = useState(1);
    const [product, setProduct] = useState(null);
    const [activeImage, setActiveImage] = useState(false)
    const pagination = 9;


    const [cartItem, setCartItem] = useRecoilState(CartState)

    const addItemToCart = (e, product)=>{
      if(cartItem.findIndex(pro => pro.product_id === product.product_id) === -1){
        setCartItem(prevState => [...prevState, {...product, total_quantity: 1}])
      }else{
        setCartItem(prevState => {
          return prevState.map((item)=>{
            return item.product_id === product.product_id ? {...item, total_quantity: (item.total_quantity)? item.total_quantity + 1 : 1} : item
          })
        })
      }
      toast(`${product.name} Added to cart `);
    }
      
    const {
      data: products,
      isLoading: isLoadingProducts,
      isSuccess: isSuccessProducts,
      isError: isErrorProducts,
      error: errorProducts,
      refetch
    } = useGetAllProductsQuery({pagination: pagination, page: page})

    const {
      data: categories,
      isLoading: isLoadingCategories,
      isSuccess: isSuccessCategories,
      isError: isErrorCategories,
      error: errorCategories
    } = useGetAllCategoriesQuery()

    const {
      data: brands,
      isLoading: isLoadingBrands,
      isSuccess: isSuccessBrands,
      isError: isErrorBrands,
      error: errorBrands
    } = useGetAllBrandsQuery()

  useEffect(()=>{
    window.scrollTo(0, 0)
    if(search != 'all'){
    const url = window.location.href;
    const parameter = url.split('/').pop();

    // Decode the URL parameter
    const decodedParameter = decodeURIComponent(search);
      handleSearchBrandOrCateory(search)
    }else{
      refetch()
    }
    return () =>{
    }
  },[]);

    const trancateText = (str)=> {
      return str.length > 40 ? str.substring(0, 40) + "..." : str;
  }

  const [input, setInput] = useState({
    search: '',
    pagination: pagination,
  })

  const [errors, setErrors] = useState({
    search: '',
  })

  const handleChange = (e)=>{
    setInput({...input, [e.target.name]: e.target.value})
  }

  const [seachProducts] = useSeachProductsMutation()

    const handleSearchData = async(e) => {
      e.preventDefault();
      try {
        setSearchData(0)
        const resp = await seachProducts({input: input, page: 1}).unwrap()
        setSearchData(resp)
        window.scrollTo(0, 0)
      } catch (err) {
        swal("Search not found");
        setSearchData(null)
      }
    };

    const handleSearchBrandOrCateory = async(name) => {
      setInput({...input, search: ''})
      const data = {  
        search: name,
        pagination: pagination,
      }
      try {
        setSearchData(0)
        const resp = await seachProducts({input: data, page: 1}).unwrap()
        setInput({...input, search: name})
        setSearchData(resp)
        window.scrollTo(0, 0)
      } catch (err) {
        swal("Search not found");
        setSearchData(null)
      }
    };

  const [nextPage] = useNextPageMutation()

  const handleNextPage = async() => {
    try {
      if(!searchData){
          setPage(page + 1)
          refetch()
          window.scrollTo(0, 0)
      }else{
        const resp = await nextPage(searchData.data.next_page_url).unwrap()
        setSearchData(resp)
        window.scrollTo(0, 0)
      }
  }catch (err) {
      swal("Error",err.data.error,"error");
  }
  };

  const handlePrevPage = async() => {
    try {
      if(!searchData){
          setPage(page - 1)
          refetch()
          window.scrollTo(0, 0)
      }else{
        const resp = await nextPage(searchData.data.prev_page_url).unwrap()
        setSearchData(resp)
        window.scrollTo(0, 0)
      }
  }catch (err) {
      swal("Error",err.data.error,"error");
  }
  };

  const handleMouseEnter = (id) => {
    setCardId(id)
    setIsHovered(true);
  };

  const handleMouseLeave = (id) => {
    setCardId(id)
    setIsHovered(false);
  };

  const numberWithCommas = (x)=>{
    if(x !==null){
      const formatedNumber = x.toLocaleString('en-US', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              });
      return formatedNumber;
    }
    return null;
}

const [getProductByIdMutation] = useGetProductByIdMutationMutation()

    const handleViewProduct = async(id)=>{
        try {
            const resp = await getProductByIdMutation(id).unwrap()
            setProduct(resp)
            setActiveImage(resp.data['0'].product.image);
            setOpen(true)
          } catch (err) {
            if(err.data.hasOwnProperty('errors') == true){
              swal("Error",err.data.error,"error");
            }else if (err.data.hasOwnProperty('error') == true){
              swal("Error",err.data.error,"error");
            }
        }
    }

    const handleCloseModal = ()=>{
      setOpen(false)
    }


    return (
        <main className='w-full bg-white flex-grow'>
            <Home />

            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative md:w-3/4 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                        {
                            (product)?
                                (product.data['1'])?
                                <div className='flex justify-center'>
                                  <div className="w-full lg:w-2/2 mt-2">
                                    <div className="bg-white ">
                                    <section class="py-6"> 
                                      <div class="container mx-auto px-4">
                                        <nav class="flex border-b border-gray-100">
                                          <ol role="list" class="flex items-center">
              
                                            <li class="text-left">
                                              <div class="flex items-center">
                                                <span class="mx-2 text-gray-400"></span>
                                                <div onClick={handleCloseModal} class="-m-1 text-gray-400 cursor-pointer hover:text-gray-700 hover:bg-gray-200 rounded-full p-3">
                                                  <XIcon className='h-4'/>
                                                </div>
                                              </div>
                                            </li>
                                          </ol>
                                        </nav>
              
                                        <div class="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-6 lg:grid-cols-5 lg:gap-16">
                                          <div class="lg:col-span-3 lg:row-end-1">
                                            <div class="lg:flex lg:items-start">
                                              <div class="lg:order-2 lg:ml-5">
                                                <div class="max-w-xl overflow-hidden rounded-lg">
                                                  <img class="h-full w-full max-w-full object-contain" src={`${(activeImage)? 'https://demoapi.autozedltd.com/uploads/'+activeImage : 'https://demoapi.autozedltd.com/uploads/'+product.data['0'].product.image}`} alt="" />
                                                </div>
                                              </div>
              
                                              <div class="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                                                <div class="flex flex-row items-start lg:flex-col">
                                                  {
                                                    (product.data['1'].product_images)?
                                                    product.data['1'].product_images.map(data => (
                                                        <button onClick={(e) => setActiveImage(data.image_name)} type="button" class={`${(activeImage == data.image_name)? 'border-2 border-gray-400' : ''} flex-0 aspect-square mb-3 h-20 w-28 overflow-hidden rounded-lg text-center`}>
                                                          <img class="h-full w-full object-contain" src={`https://demoapi.autozedltd.com/uploads/`+data.image_name} alt="" />
                                                        </button>
                                                      ))
                                                    :null
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
              
                                          <div class="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                                            <h1 class="text-gray-900 text-lg mt-3">{product.data['0'].product.name}</h1>
              
                                            <div class="mt-5 flex items-center border-b pb-4">
                                              <div class="flex items-center">
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                                <svg class="block h-4 w-4 align-middle text-yellow-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class=""></path>
                                                </svg>
                                              </div>
                                              <p class="ml-2 text-sm font-medium text-gray-500">4 Reviews</p>
                                            </div>
              
                                            <h2 class="mt-3 text-xs uppercase text-gray-400">Product Information</h2>
                                            <div class="mt-3 flex select-none items-center gap-1">
                                              <div className="flex flex-col text-xs gap-y-2">
                                                <div className="flex items-center gap-x-6 justify-start">
                                                  <p className="mr-4 text-gray-600">Brand:</p>
                                                  <p className="text-gray-700">{(product.data['0'].product.brand != null)? product.data['0'].product.brand :(product.data['0'].product.custome_brand != null)? product.data['0'].product.custome_brand : 'None'}</p>
                                                </div>
                                                <div className="flex items-center gap-x-6 justify-start">
                                                  <p className="mr-4 text-gray-600">Category:</p>
                                                  <p className="text-gray-700">{product.data['0'].product.category}</p>
                                                </div>
                                              </div>
                                            </div>

                                            <h2 class="mt-4 text-sm text-gray-400">Product Description</h2>
                                            <div class="mt-3 flow-root text-xs text-gray-600">
                                              {product.data['0'].product.description}
                                            </div>
              
                                            <div class="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
                                              <div class="flex space-x-2 items-end">
                                                <h1 class="text-2xl text-red-500">ZK{numberWithCommas(parseInt(product.data['0'].product.price))}</h1>
                                                <span class="text-lg text-gray-500 line-through">K{numberWithCommas(parseInt(product.data['0'].product.old_price))}</span>
                                              </div>
              
                                              <button onClick={(e)=>addItemToCart(e,product.data['0'].product)} class="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-rose-600 bg-none px-3 py-1.5 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-rose-700">
                                                <ShoppingCartIcon className='h-5 mr-1'/>
                                                <div className='text-sm '>Add to cart</div>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                    </div>
                                  </div>
                                </div>
                                :<div className='text-sm '>Add to cart</div>
                            :<div className='text-sm '>Add </div>
                            }
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>

            <div className='flex items-center justify-between'>
            <div className="w-full flex-grow">
                <div className='w-full flex items-center justify-center bg-gray-900 text-white p-6'>
                    <h1 className="text-lg font-semibold">Products</h1>
                </div>
            </div>
            </div>
            <div className='flex justify-center items-center mt-4'>
              <form onSubmit={handleSearchData} autoComplete='off'>
                <div>
                  <div class="relative w-96 outline-none">
                    <input 
                    type="search" 
                    id="search-dropdown" 
                    name="search" 
                    class="block w-full px-5 py-2 outline-none text-gray-700 border-none focus:ring-rose-600 bg-gray-100 rounded" 
                    placeholder="Search..." 
                    value={input.search} 
                    onChange={handleChange}
                    required />
                    <button type="submit" class="absolute top-0 right-0 px-5 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-r-lg border border-gray-100 hover:bg-gray-200">
                        <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        <span class="sr-only">Search</span>
                    </button>
                  </div>
                  <p className="block text-sm font-medium text-red-600 p-0 m-0">{errors.search}</p>
                </div>
              </form>
            </div>
            {
              (isSuccessProducts)?
                  (searchData)?
                    <div className='flex justify-center'>
                      <aside className="relative min-h-screen items-center w-72 hidden sm:block my-24">
                        <div className='flex items-center justify-start ml-8 uppercase font-semibold text-md mb-2 border-b border-gray-200'>
                          Categories
                        </div>
                          {
                            (isSuccessCategories)?
                              categories.data.map((data)=>(
                              <div className="p-2 ml-6">
                                  <button onClick={(e)=>handleSearchBrandOrCateory(data.name)} className="text-gray-500 text-sm hover:text-gray-300">{data.name}</button>
                              </div>
                              ))
                            :null
                          }
                          <div className='flex items-center justify-start ml-8 uppercase font-semibold text-md mb-2 pt-4 border-b border-gray-200'>
                            Brands
                          </div>
                        {
                            (isSuccessBrands)?
                              brands.data.map((data)=>(
                              <div className="p-2 ml-6">
                                  <button onClick={(e)=>handleSearchBrandOrCateory(data.name)} className="text-gray-500 text-sm hover:text-gray-300">{data.name}</button>
                              </div>
                              ))
                            :null
                          }
                      </aside>
                      <div className='w-full lg:w-2/2 py-24'>
                        <div className="bg-white">
                          <section class="text-gray-600 body-font">
                            <div class="container px-3 mx-auto">
                              <div class="flex flex-wrap">
                              {
                                (searchData.data.data.length > 0)?
                                searchData.data.data.map(data => (
                                  <div class={`${isHovered && cardId == data.product_id ? 'transform scale-110 transition-all duration-500 z-10' : ''} border-2 bg-white border-gray-200 hover:shadow-xl round-md border-opacity-60 lg:w-1/3 md:w-1/2 w-full`}
                                  onMouseEnter={(e)=>handleMouseEnter(data.product_id)}
                                  onMouseLeave={(e)=>handleMouseLeave(data.product_id)}
                                >
                                  <a class={`block relative h-48 rounded overflow-hidden`}
                                    
                                  >
                                    <img 
                                        loading="lazy"
                                        className="object-contain h-60 w-full rounded-t-md shodow-lg group-hover:opacity-50"
                                        src={`https://demoapi.autozedltd.com/uploads/`+data.image} 
                                        alt="Image" 
                                    />
                                  </a>
                                  <div class="pl-2 mt-4">
                                      <h2 class="flex items-center space-x-2 text-gray-600 title-font text-lg font-medium">
                                        <div className='text-md text-red-500'>ZK {numberWithCommas(parseInt(data.price))}</div>
                                        <div className='text-xs line-through'> ZK{(data.old_price)? numberWithCommas(parseInt(data.old_price)) : '00.00'}</div>
                                      </h2>
                                      <h2 class="text-gray-800 title-font text-md mt-3">{trancateText(data.name)}</h2>
                                      <h2 class="text-gray-400 font-semibold text-xs mt-2">{(data.brand != null)? data.brand :(data.custome_brand != null)? data.custome_brand : 'None'}</h2>
                                      <h2 class="text-gray-400 title-font text-xs font-medium">{data.category}</h2>
                                      <div class="flex items-center">
                                          <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                          <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                          <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                          <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                          <svg aria-hidden="true" class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                          <p class="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">4.95 out of 5</p>
                                      </div>
                                      <p class="text-gray-500 title-font text-md font-medium pt-2 pb-2 h-12">{trancateText(data.description)}</p>
                                  </div>
                                  <div className='flex items-center justify-between pt-2 pb-4 pl-2 pr-6'>
                                    <div onClick={(e)=>addItemToCart(e,data)} className="">
                                          <button className="inline-flex w-full justify-center bg-rose-700 px-3 py-2 text-sm rounded-sm font-semibold text-white hover:bg-rose-600 uppercase">
                                            <ShoppingCartIcon className='h-5 mr-1'/>
                                            <div className='text-sm '>Add to cart</div>
                                          </button>
                                    </div>
                                    <div onClick={(e)=>handleViewProduct(data.product_id)} className="text-gray-400 hover:text-gray-600 cursor-pointer">
                                      <EyeIcon className='h-7'/>
                                    </div>
                                  </div>
                                </div>
                                ))
                                :null
                              }
                              </div>

                              {
                                (searchData)?
                                  <div className='w-full flex justify-center items-center space-x-3 pt-10 pr-16'>
                                      {
                                          (searchData.data.prev_page_url)?
                                              <ChevronLeftIcon onClick={handlePrevPage} className='h-8 rounded-md text-gray-500 cursor-pointer hover:text-gray-400'/>
                                          :
                                              <ChevronLeftIcon className='h-8 rounded-md text-gray-300 cursor-pointer'/>
                                      }
                                      <div className='text-gray-500'>{searchData.data.current_page} / {searchData.data.last_page}</div>
                                      {
                                          (searchData.data.last_page > searchData.data.current_page)?
                                              <ChevronRightIcon onClick={handleNextPage} className='h-8 rounded-md text-gray-500 cursor-pointer hover:text-gray-400' />
                                              :
                                              <ChevronRightIcon className='h-8 rounded-md text-gray-300 cursor-pointer' />
                                      }
                                  </div>
                                :null
                              }
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  :(!searchData && products)?
                  <div className='flex justify-center'>
                  <aside className="relative min-h-screen items-center w-72 hidden sm:block my-24">
                    <div className='flex items-center justify-start ml-8 uppercase font-semibold text-md mb-2 border-b border-gray-200'>
                      Categories
                    </div>
                      {
                        (isSuccessCategories)?
                          categories.data.map((data)=>(
                          <div className="p-2 ml-6">
                              <button onClick={(e)=>handleSearchBrandOrCateory(data.name)} className="text-gray-500 text-sm hover:text-gray-300">{data.name}</button>
                          </div>
                          ))
                        :null
                      }
                      <div className='flex items-center justify-start ml-8 uppercase font-semibold text-md mb-2 pt-4 border-b border-gray-200'>
                        Brands
                      </div>
                    {
                        (isSuccessBrands)?
                          brands.data.map((data)=>(
                          <div className="p-2 ml-6">
                              <button onClick={(e)=>handleSearchBrandOrCateory(data.name)} className="text-gray-500 text-sm hover:text-gray-300">{data.name}</button>
                          </div>
                          ))
                        :null
                      }
                  </aside>
                  <div className='w-full lg:w-2/2 py-24'>
                    <div className="bg-white">
                      <section class="text-gray-600 body-font">
                        <div class="container px-3 mx-auto">
                          <div class="flex flex-wrap">
                          {
                              (products.data.data.length > 0)?
                              products.data.data.map(data => (
                                <div class={`${isHovered && cardId == data.product_id ? 'transform scale-110 transition-all duration-500 z-10' : ''} border-2 bg-white border-gray-200 hover:shadow-xl round-md border-opacity-60 lg:w-1/3 md:w-1/2 w-full`}
                                  onMouseEnter={(e)=>handleMouseEnter(data.product_id)}
                                  onMouseLeave={(e)=>handleMouseLeave(data.product_id)}
                                >
                                  <a class={`block relative h-48 rounded overflow-hidden`}
                                    
                                  >
                                    <img 
                                        className="object-contain h-60 w-full rounded-t-md shodow-lg group-hover:opacity-50"
                                        src={`https://demoapi.autozedltd.com/uploads/`+data.image} 
                                        alt="Image" 
                                    />
                                  </a>
                                  <div class="pl-2 mt-4">
                                      <h2 class="flex items-center space-x-2 text-gray-600 title-font text-lg font-medium">
                                        <div className='text-md text-red-500'>ZK {numberWithCommas(parseInt(data.price))}</div>
                                        <div className='text-xs line-through'> ZK{(data.old_price)? numberWithCommas(parseInt(data.old_price)) : '00.00'}</div>
                                      </h2>
                                      <h2 class="text-gray-800 title-font text-md mt-3">{trancateText(data.name)}</h2>
                                      <h2 class="text-gray-400 font-semibold text-xs mt-2">{(data.brand != null)? data.brand :(data.custome_brand != null)? data.custome_brand : 'None'}</h2>
                                      <h2 class="text-gray-400 title-font text-xs font-medium">{(data.category != null)? data.category : '...'}</h2>
                                      <div class="flex items-center">
                                          <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                          <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                          <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                          <svg aria-hidden="true" class="w-5 h-5 text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                          <svg aria-hidden="true" class="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                          <p class="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">4.95 out of 5</p>
                                      </div>
                                      <p class="text-gray-500 title-font text-md font-medium pt-2 pb-2 h-12">{trancateText(data.description)}</p>
                                  </div>
                                  <div className='flex items-center justify-between pt-2 pb-4 pl-2 pr-6'>
                                    <div onClick={(e)=>addItemToCart(e,data)} className="">
                                          <button className="inline-flex w-full justify-center bg-rose-700 px-3 py-2 text-sm rounded-sm font-semibold text-white hover:bg-rose-600 uppercase">
                                            <ShoppingCartIcon className='h-5 mr-1'/>
                                            <div className='text-sm '>Add to cart</div>
                                          </button>
                                    </div>
                                    <div onClick={(e)=>handleViewProduct(data.product_id)} className="text-gray-400 hover:text-gray-600 cursor-pointer">
                                      <EyeIcon className='h-7'/>
                                    </div>
                                  </div>
                                </div>
                              ))
                              :null
                            }
                          </div>
                          {
                            (!searchData)?
                              <div className='w-full flex justify-center items-center space-x-3 pt-10 pr-16'>
                                  {
                                      (products.data.prev_page_url)?
                                          <ChevronLeftIcon onClick={handlePrevPage} className='h-8 rounded-md text-gray-500 cursor-pointer hover:text-gray-400'/>
                                      :
                                          <ChevronLeftIcon className='h-8 rounded-md text-gray-300 cursor-pointer'/>
                                  }
                                  <div className='text-gray-500'>{products.data.current_page} / {products.data.last_page}</div>
                                  {
                                      (products.data.last_page > products.data.current_page)?
                                          <ChevronRightIcon onClick={handleNextPage} className='h-8 rounded-md text-gray-500 cursor-pointer hover:text-gray-400' />
                                          :
                                          <ChevronRightIcon className='h-8 rounded-md text-gray-300 cursor-pointer' />
                                  }
                              </div>
                            :null
                          }
                          {
                            (searchData)?
                              <div className='w-full flex justify-center items-center space-x-3 pt-10 pr-16'>
                                  {
                                      (searchData.data.prev_page_url)?
                                          <ChevronLeftIcon onClick={handlePrevPage} className='h-8 rounded-md text-gray-500 cursor-pointer hover:text-gray-400'/>
                                      :
                                          <ChevronLeftIcon className='h-8 rounded-md text-gray-300 cursor-pointer'/>
                                  }
                                  <div className='text-gray-500'>{searchData.data.current_page} / {searchData.data.last_page}</div>
                                  {
                                      (searchData.data.last_page > searchData.data.current_page)?
                                          <ChevronRightIcon onClick={handleNextPage} className='h-8 rounded-md text-gray-500 cursor-pointer hover:text-gray-400' />
                                          :
                                          <ChevronRightIcon className='h-8 rounded-md text-gray-300 cursor-pointer' />
                                  }
                              </div>
                            :null
                          }
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                  :(!searchData == '0')?
                    <div>
                      failed
                    </div>
                  :null
                :(isLoadingProducts)?
                  <LoadingSpinner />
                :(isErrorProducts)?
                  <div>
                    failed
                  </div>
              :null
            }
        </main>
    )
}

export default ShopView;
