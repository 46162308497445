import { PencilAltIcon, TrashIcon, UserCircleIcon } from '@heroicons/react/outline'
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { useGetUserClientRoleByUserIdQuery } from '../../Features/clientRolesSlice';
import { useGetUserRolesByUserIdQuery } from '../../Features/rolesSlice';
import { useDeleteUserMutation, useGetUserProfileByIdQuery } from '../../Features/usersSlice';
import LoadingSpinner from '../LoadingSpinner';


function ManageUserProfileView() {

    const navigate = useNavigate();
    let { user_id } = useParams();

    const {
        data: user,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUserProfileByIdQuery(user_id)

    const {
        data: userRoles,
        isLoading: userRolesIsLoading,
        isSuccess: userRolesIsSuccess,
        isError: userRolesIsError,
        error: userRolesError
    } = useGetUserRolesByUserIdQuery(user_id)

    const {
        data: userClientRole,
        isLoading: userClientRoleIsLoading,
        isSuccess: userClientRoleIsSuccess,
        isError: userClientRoleIsError,
        error: userClientRoleError
    } = useGetUserClientRoleByUserIdQuery(user_id)

    const [deleteUser] = useDeleteUserMutation();

    const editUser = (e,id)=>{
        navigate("/user/user-profile-edit/"+id)
    }

    const manageUserRoles = (e,id)=>{
        navigate("/user/manage-user/roles/"+id)
    }

    const manageUserClientRoles = (e,id)=>{
        navigate("/user/manage-user-client-roles/user-client-roles/"+id)
    }

    const handDeleteUser = async(e,id)=>{
        if(window.confirm("Are you sure you want to delete this user?")){
            try {
            const resp = await deleteUser(user_id).unwrap();
            swal("Success",resp.message);
            navigate(-1);
        } catch (err) {
            swal("Error",err.message,'error');
        }
          }
    }

        return (
            <div className="bg-gray-100 flex  flex-col">
                <div className="flex items-center justify-center bg-white p-1">
                    <h1 className="text-sm text-gray-800 uppercase font-semibold p-2">manage user profile</h1>
                </div>
                {
                (isSuccess)?
                    (user.data)?
                        <div className="">
                           <div className="md:flex justify-center mt- p-8">
                                <div className="bg-gray-200 shadow-sm pr-0 pl-0 pt-2 pb-2 rounded-md">
                                    <div className="flex justify-center md:pr-60 md:pl-60">
                                        <UserCircleIcon className="h-20 text-gray-500" />
                                    </div>
                                        <div className="flex justify-between border-b border-gray-300">
                                            <p className="ml-20 p-2 font-semibold text-sm">Name</p>
                                            <p className="mr-20 p-2 text-sm uppercase">{user.data.fname} {user.data.lname}</p>
                                        </div>
                                        <div className="flex justify-between border-b border-gray-300">
                                            <p className="ml-20 p-2 font-semibold text-sm">Email</p>
                                            <p className="mr-20 p-2 text-sm">{user.data.email}</p>
                                        </div>
                                        <div className="flex justify-between border-b border-gray-300">
                                            <p className="ml-20 p-2 font-semibold text-sm">Phone No.</p>
                                            <p className="mr-20 p-2 text-sm">{user.data.phone}</p>
                                        </div>
                                        <div className="flex justify-between border-b border-gray-300">
                                            <p className="ml-20 p-2 font-semibold text-sm">ID</p>
                                            <p className="mr-20 p-2 text-sm">{user.data.user_id}</p>
                                        </div>
                                        <div className="flex justify-between border-b border-gray-300">
                                            <p className="ml-20 p-2 font-semibold text-sm">Business/Company</p>
                                            <p className="mr-20 p-2 text-sm">{user.data.name} {user.data.business_type}</p>
                                        </div>
                                        <div className="flex justify-between border-b border-gray-300">
                                            <p className="ml-20 p-2 font-semibold text-sm">Gender</p>
                                            <p className="mr-20 p-2 text-sm">{user.data.gender}</p>
                                        </div>
                                        <div className="flex flex-col justify-center mt-4">
                                            <p className="p-2 font-semibold text-sm text-gray-500 uppercase flex justify-center">User System Roles</p>
                                            {
                                                (userRolesIsSuccess)?
                                                    (userRoles.hasOwnProperty('success') === true && userRoles.data.length > 0)?
                                                        <table className="w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-500 shadow-lg">
                                                                <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                                >
                                                                    Role
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="flex justify-center px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                                >
                                                                    Description
                                                                </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-gray-300 divide-y divide-gray-300">
                                                            { (userRoles)?userRoles.data.map(data => (
                                                                    <tr key={data.id} className="hover:bg-gray-400">
                                                                        <td className="px-6 py-1 whitespace-nowrap">
                                                                        <div className="flex items-center">
                                                                            <div className="text-sm font-medium text-gray-900">{data.name}</div>
                                                                        </div>
                                                                        </td>
                                                                        <td className="px-2 py-1 whitespace-nowrap text-sm ">
                                                                            <div className=''>{data.description}</div>
                                                                        </td>
                                                                    </tr>
                                                                    ))
                                                                    : ''
                                                                }
                                                            </tbody>
                                                        </table>
                                                    : <div>{userRoles.message}</div>
                                                : userRolesIsLoading
                                                    ? <div>Loading...</div>
                                                    : userRolesIsError
                                                        ? <div>{userRolesError.data.error}</div>
                                                        : ''
                                            }
                                        </div>
                                        <div onClick={(e)=>manageUserRoles(e, user.data.id)} className="flex justify-center mt-2 bg-red-900 hover:bg-red-800 cursor-pointer mt-2 border-t border-gray-200 p-1">
                                            <div className="flex flex-col">
                                                <label className="text-sm text-gray-200 uppercase text-white cursor-pointer">Manage system user roles</label>
                                            </div>                           
                                         </div>
                                         <div className="flex flex-col justify-center mt-4">
                                            <p className="p-2 font-semibold text-sm text-gray-500 uppercase flex justify-center">Client User Role</p>
                                            {
                                                (userClientRoleIsSuccess)?
                                                    (userRoles.hasOwnProperty('success') === true && userRoles.data.length > 0)?
                                                        <table className="w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-500 shadow-lg">
                                                                <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="flex justify-center px-2 py-1 text-left text-xs font-medium text-gray-100 uppercase tracking-wider"
                                                                >
                                                                    Description
                                                                </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="bg-gray-300 divide-y divide-gray-300">
                                                            { (userClientRole)?userClientRole.data.map(data => (
                                                                    <tr key={data.id} className="hover:bg-gray-400">
                                                                        <td className="px-6 py-1 whitespace-nowrap">
                                                                        <div className="flex items-center">
                                                                            <div className="text-sm font-medium text-gray-900">{data.description}</div>
                                                                        </div>
                                                                        </td>
                                                                    </tr>
                                                                    ))
                                                                    : ''
                                                                }
                                                            </tbody>
                                                        </table>
                                                    : <div>{userClientRole.message}</div>
                                                : userClientRoleIsLoading
                                                    ? <div>Loading...</div>
                                                    : userClientRoleIsError
                                                        ? <div>{userClientRoleError.data.error}</div>
                                                        : ''
                                            }
                                        </div>
                                         <div onClick={(e)=>manageUserClientRoles(e, user.data.id)} className="flex justify-center mt-2 bg-indigo-900 hover:bg-indigo-800 cursor-pointer mt-2 border-t border-gray-200 p-1">
                                            <div className="flex flex-col">
                                                <label className="text-sm text-gray-200 uppercase text-white cursor-pointer">Manage client user role</label>
                                            </div>                           
                                         </div>
                                        <div className="flex justify-center mt-1 space-x-4 border-t border-gray-300 p-4">
                                            <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <label onClick={(e)=>handDeleteUser(e, user.data.id)} className="flex justify-center space-x-2 items-center text-sm bg-red-600 hover:bg-red-700 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text-white flex justify-center">
                                                        Delete
                                                    </div>
                                                    <div className="text-white flex justify-center">
                                                        <TrashIcon className="h-4 " />
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="flex flex-col items-center  w-28 rounded-md">                                  
                                                <label onClick={(e)=>editUser(e, user.data.id)} className="flex justify-center space-x-2 items-center text-sm bg-blue-400 hover:bg-blue-500 cursor-pointer pl-2 pr-2 pt-1 pb-1 w-28 rounded-md">
                                                    <div className="text-white flex justify-center">
                                                        Edit user
                                                    </div>
                                                    <div className="text-white flex justify-center">
                                                        <PencilAltIcon className="h-4 " />
                                                    </div>
                                                </label>
                                            </div>                           
                                         </div>
                                </div>
                           </div>
                        </div>
                    :
                        <div>{user.error}</div>
                    : isLoading?
                    <LoadingSpinner />
                    : isError
                        ? error.data.error
                        : ''
              }
            </div>
        )
}

export default ManageUserProfileView;
